<template>
  <div>
    <div class="bg">
      <div class="main-width banner">
        <!-- 左侧文字介绍 -->
        <div class="left-text">
          <h2 class="left-title">金企盾双重预防管理系统</h2>
          <div class="left-bottom">
            <div>
              <img src="../assets/index/duigou.png" alt="">
              <span>落实双重预防机制建设</span>
            </div>
            <div>
              <img src="../assets/index/duigou.png" alt="">
              <span>实现分层管控风险</span>
            </div>
            <div>
              <img src="../assets/index/duigou.png" alt="">
              <span>高效消除隐患</span>
            </div>
            <div>
              <img src="../assets/index/duigou.png" alt="">
              <span>遏制事故发生</span>
            </div>
          </div>
        </div>
        <!-- 右侧手机登录 -->
        <div class="registry-box">
          <top class="form-box"></top>
        </div>
      </div>
    </div>
    <!-- 八大功能模块 -->
    <div class="eight-module">
      <div class="main-width">
        <div class="subtitle-box">
          <div class="subtitle">八大功能模块</div>
          <el-divider>实现企业安全风险自辨自控、隐患自查自治</el-divider>
        </div>
        <div class="eight-img">
          <div class="eight-text-1">
            <div class="eight-title">风险分级管控</div>
            <div class="eight-title-text">辨识企业风险，责任分级管控</div>
          </div>
          <div class="eight-text-2">
            <div class="eight-title">隐患排查治理</div>
            <div class="eight-title-text">制定排查计划，高效消除隐患</div>
          </div>
          <div class="eight-text-3">
            <div class="eight-title">随手拍</div>
            <div class="eight-title-text">随时随地发现隐患，随手一拍上报隐患</div>
          </div>
          <div class="eight-text-4">
            <div class="eight-title">奖惩管理</div>
            <div class="eight-title-text">落实奖惩机制，调动全员参与</div>
          </div>
          <div class="eight-text-5">
            <div class="eight-title">风险告知卡</div>
            <div class="eight-title-text">明示风险隐患，指导应急处置</div>
          </div>
          <div class="eight-text-6">
            <div class="eight-title">通知待办</div>
            <div class="eight-title-text">任务消息及时推送，任务临期自动提醒</div>
          </div>
          <div class="eight-text-7">
            <div class="eight-title">数据上报</div>
            <div class="eight-title-text">预留数据接口，自主审核上报</div>
          </div>
          <div class="eight-text-8">
            <div class="eight-title">企业安全一张图</div>
            <div class="eight-title-text">风险隐患数据统计，风险空间动态展示</div>
          </div>
          <!-- <div :class="isarrow ? 'dobule-arrow' : 'dobule-arrow up'"></div> -->
          <div class="dobule-arrow"></div>
        </div>
        <div class="bottom-btn eight-btn">
          <el-button class="liner-btn" type="primary" @click="openDialog">预约使用</el-button>
        </div>
      </div>
      <div class="left-img"></div>
      <div class="right-img"></div>
    </div>
    <!-- 核心价值 -->
    <div class="core-value">
      <div class="main-width">
        <div class="subtitle-box">
          <div class="subtitle">核心价值</div>
          <el-divider>实现企业安全风险自辨自控、隐患自查自治</el-divider>
        </div>
        <div class="core-mediumn">
          <div class="core-left">
            <div class="core-title blue-title">
              <div>使用前</div>
            </div>
            <div class="core-text">
              <div class="core-first">
                <span class="core-num num-blue">01</span>
                <span class="core-num-title num-title-black">事后管理，亡羊补牢</span>
              </div>
              <div class="core-main main-color">企业风险难以提前预知，预防事故难度大，安全管理度难以落实，无法有效预防事故发生</div>
            </div>
            <div class="core-text">
              <div class="core-first">
                <span class="core-num num-blue">02</span>
                <span class="core-num-title num-title-black">标准不明，责任不清</span>
              </div>
              <div class="core-main main-color">风险分级标准不清晰，难以预知全厂风险；责任难以部门、岗位、人员分级逐层传递，责任制难以落实</div>
            </div>
            <div class="core-text">
              <div class="core-first">
                <span class="core-num num-blue">03</span>
                <span class="core-num-title num-title-black">排查治理，流程混乱</span>
              </div>
              <div class="core-main main-color">隐患排查与治理流程缺乏规范化管理手段，隐患整改、验收流程不清晰，隐患治理效率难以提升</div>
            </div>
            <div class="core-text">
              <div class="core-first">
                <span class="core-num num-blue">04</span>
                <span class="core-num-title num-title-black">双防机制，推进不易</span>
              </div>
              <div class="core-main main-color">双重预防机制落地推进不易，基层员工无法切身体会该机制带来的安全保障收益</div>
            </div>
          </div>
          <div class="core-bg">
            <div class="core-circle">
            </div>
            <div class="core-vs">
              <span class="core-vs-text">vs</span>
              <img src="@/assets/index/sjx.png" alt="">
            </div>
          </div>

          <div class="core-right">
            <div class="core-title fff-title">
              <div>使用后</div>
            </div>
            <div class="core-text">
              <div class="core-first">
                <span class="core-num">01</span>
                <span class="core-num-title">提前预防，事事安心</span>
              </div>
              <div class="core-main">提前预知企业风险，针对风险排查隐患，将风险控制在隐患之前，将隐患消除在事故之前</div>
            </div>
            <div class="core-text">
              <div class="core-first">
                <span class="core-num">02</span>
                <span class="core-num-title">责任明确，权责清晰</span>
              </div>
              <div class="core-main">解决企业全责不清的问题，辅助安全责任考核制度落地</div>
            </div>
            <div class="core-text">
              <div class="core-first">
                <span class="core-num">03</span>
                <span class="core-num-title">隐患排查，闭环管理</span>
              </div>
              <div class="core-main">隐患排查形成闭环管理，可实现隐患识别、上报、排查、整改完整工作流</div>
            </div>
            <div class="core-text">
              <div class="core-first">
                <span class="core-num">04</span>
                <span class="core-num-title">奖励机制，全员参与</span>
              </div>
              <div class="core-main">双重预防机制搭配奖惩机制与随手拍功能，基层员工随时随地发现隐患、上报隐患即可获得奖励，有效促进双防机制落地</div>
            </div>
          </div>
        </div>
        <div class="bottom-btn">
          <el-button class="liner-btn" type="primary" @click="openDialog">预约使用</el-button>
        </div>

      </div>
    </div>
    <!-- 行业应用背景 -->
    <div class="ten-industry">
      <div class="main-width">
        <div class="subtitle-box">
          <div class="subtitle">行业应用背景</div>
          <el-divider>匹配数十个行业的双重预防需求</el-divider>
        </div>
        <!-- 10个图片部分 -->
        <div class="ten-img">
          <div style="margin:0 auto;width:1196px">
            <div class="ten-top">
              <div class="item">
                <div class="img-1 img-mask">
                  <div class="img-1-mask"></div>
                </div>
                <span>化工企业</span>
              </div>
              <div class="small-item">
                <div class="img-2 img-mask img-small">
                  <div class="img-2-mask"></div>
                </div>
                <span>冶金企业</span>
              </div>
              <div class="small-item">
                <div class="img-3 img-small img-mask">
                  <div class="img-3-mask">
                  </div>
                </div>
                <span>医院</span>
              </div>
              <div class="small-item">
                <div class="img-4 img-small img-mask">
                  <div class="img-4-mask">
                  </div>
                </div>
                <span>建设工地</span>
              </div>
              <div class="small-item">
                <div class="img-5 img-small img-mask">
                  <div class="img-5-mask">
                  </div>
                </div>
                <span>建材制造企业</span>
              </div>
            </div>
            <div class="img-bottom">
              <div class="small-item" style="margin-left:0">
                <div class="img-6 img-mask">
                  <div class="img-6-mask">
                  </div>
                </div>
                <span>机械制造企业</span>
              </div>
              <div class="small-item">
                <div class="img-7 img-small img-mask">
                  <div class="img-7-mask">
                  </div>
                </div>
                <span>非煤矿山厂</span>
              </div>
              <div class="small-item">
                <div class="img-8 img-small img-mask">
                  <div class="img-8-mask">
                  </div>
                </div>
                <span>纺织企业</span>
              </div>
              <div class="small-item">
                <div class="img-9 img-small img-mask">
                  <div class="img-9-mask">
                  </div>
                </div>
                <span>烟草企业</span>
              </div>
              <div class="item" style="margin-left:10px">
                <div class="img-10 img-mask">
                  <div class="img-10-mask">
                  </div>
                </div>
                <span>钢铁厂</span>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- 预约使用 -->
    <div class="enter-name">
      <div class="main-width">
        <div class="subtitle-box">
          <div class="subtitle" style="font-size: 24px; color: #FFFFFF; font-weight: 500;">智能化管控，落实双重预防机制建设</div>
        </div>
        <top :title="false" :inline="true" :form-style="false"></top>
      </div>
    </div>
    <!-- 典型客户 -->
    <div class="typical-customer">
      <div>
        <div class="subtitle-box">
          <div class="subtitle">典型客户</div>
          <el-divider>百强企业的共同选择</el-divider>
        </div>
        <div calss="many-customer">
          <div class="customer-img">
            <div class="block">
              <el-carousel arrow="never" height="300px" indicator-position="none" :autoplay="false">
                <el-carousel-item class="carousel-box" v-for="(item, index) in customerList" :key="index">
                  <div class="customer-box" v-for="(i, index) in item" :key="index">
                    <img :src="i.url" alt="">
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- 企业品牌实力 -->
    <div class="brand-strength">
      <div class="main-width">
        <div class="subtitle-box">
          <div class="subtitle">企业品牌实力</div>
          <el-divider>创新、务实、为客户创造价值</el-divider>
        </div>
        <div class="four-strength">
          <div :style="{ backgroundImage: `url(${item.bgurl})` }" v-for="item in strengthList" :key="item.id"
            @mouseenter="firstStrengthHoverFn(item.id)"
            :class="item.id == firstStrength ? 'active mr' : 'first-strength mr'" ref="firstHoveRef">
            <div class="first-mask mask">
              <img class="mask-icon icondelay" :src="item.url" alt="">
              <div class="mask-text1 text1delay">{{ item.text1 }}</div>
              <div class="mask-text2 text2delay">{{ item.text2 }}</div>
            </div>
          </div>
        </div>
        <div class="bottom-btn">
          <el-button class="liner-btn" type="primary" @click="openDialog">预约使用</el-button>
        </div>
      </div>
    </div>
    <!-- 底部列表 -->
    <articleList></articleList>
    <!-- form弹框 -->
    <formDialog :dialogVisible.sync="dialogVisible" ref="formRef"></formDialog>
    <!-- <iframeBox :dialogVisible.sync="dialogVisible"></iframeBox> -->

    <!-- 底部导航 -->
    <foot></foot>
  </div>
</template>

<script>
import articleList from '@/components/ArticleList.vue'
import formDialog from '@/components/dialogForm.vue'
import top from '@/components/TopForm.vue'
import foot from '@/components/FootNavBar.vue'


export default {
  name: 'HomeView',
  components: {
    top, foot, formDialog, articleList
  },
  created() {
    this.firstStrengthHoverFn(1)
  },
  mounted() {
    this.$refs.formRef.$refs.diaRef.rendered = true
  },
  data() {

    return {
      imgURL(data, type) {
        return this.$imgURL + data;
      },
      customerList: [
        [
          { url: require('@/assets/customerLogo/zgsh.svg') },
          { url: require('@/assets/customerLogo/zgsy.svg') },
          { url: require('@/assets/customerLogo/jljt.svg') },
          { url: require('@/assets/customerLogo/sqjt.svg') },
          { url: require('@/assets/customerLogo/sx.svg') },
          { url: require('@/assets/customerLogo/wh.svg') },
          { url: require('@/assets/customerLogo/ks.svg') },
          { url: require('@/assets/customerLogo/qdsos.svg') },
          { url: require('@/assets/customerLogo/jbsh.svg') },
          { url: require('@/assets/customerLogo/sdho.svg') },
          { url: require('@/assets/customerLogo/tj.svg') },
          { url: require('@/assets/customerLogo/hlhs.svg') },
          { url: require('@/assets/customerLogo/qlsh.svg') },
          { url: require('@/assets/customerLogo/lzsh.svg') },
          { url: require('@/assets/customerLogo/dlxt.svg') },
        ],
        /* [
          { url: require('@/assets/customer/青岛石化.png') },
          { url: require('@/assets/customer/济南炼化.png') },
          { url: require('@/assets/customer/山东科信.png') },
          { url: require('@/assets/customer/山东晨旭.png') },
          { url: require('@/assets/customer/山东天邦.png') },
          { url: require('@/assets/customer/胜凯废气.png') },

        ] */
      ],
      isarrow: false,//剪头动画
      select: '1',
      channelId: '277',
      mobile: '',
      authCode: '',
      dialogVisible: false,//form弹框表单
      firstStrength: false,//active控制变量
      secondStrength: false,
      thirdStrength: false,
      fourthStrength: false,
      strengthList: [
        {
          url: require('@/assets/index/rjkf.png'), bgurl: require('@/assets/index/sl1.jpg'),
          text1: '20年软件开发经验',
          text2: '打造双重预防、作业票全流程管理等多款功能齐全、规范标准的产品', id: '1'
        },
        {
          url: require('@/assets/index/升级迭代.png'), bgurl: require('@/assets/index/sl2.jpg'), text1: '支持升级迭代', text2: '各模块独立设计，可拓展性强，支持升级迭代', id: '2'
        },
        {
          url: require('@/assets/index/国产化软硬件.png'), bgurl: require('@/assets/index/sl3.jpg'), text1: '支持国产化软硬件生态', text2: '与国内服务器、操作系统、中间件等厂家达成合作，支持国产软硬件', id: '3'
        },
        {
          url: require('@/assets/index/低代码开发平台.png'), bgurl: require('@/assets/index/sl4.jpg'), text1: '低代码开发平台', text2: ' 采用轻骑兵低代码开发平台，极大缩短项目交付周期，降低开发成本', id: '4'
        },
      ],
      articalList: [{ text: '冷却塔棕色煤炭', id: 1, url: 'https://t7.baidu.com/it/u=1819248061,230866778&fm=193&f=GIF' }, { text: '化工厂科技人员手拿操作器', id: 2, url: 'https://t7.baidu.com/it/u=1595072465,3644073269&fm=193&f=GIF' }, { text: '工厂智能化场景', id: 3, url: 'https://t7.baidu.com/it/u=2621658848,3952322712&fm=193&f=GIF' }, { text: '生产车间', id: 4, url: 'https://t7.baidu.com/it/u=2359570649,2574326109&fm=193&f=GIF' },],
      textList: [{ text: '双重预防究竟是哪双重，预防了什么？', id: 1, date: '2022-9-27' }, { text: '双重预防究竟是哪双重，预防了什么？', id: 2, date: '2022-9-27' }, { text: '双重预防究竟是哪双重，预防了什么？', id: 3, date: '2022-9-27' }, { text: '双重预防究竟是哪双重，预防了什么？', date: '2022-9-27', id: 4 }, { text: '双重预防究竟是哪双重，预防了什么？', id: 5, date: '2022-9-27' }, { text: '双重预防究竟是哪双重，预防了什么？', id: 6, date: '2022-9-27' }, { text: '双重预防究竟是哪双重，预防了什么？', id: 7, date: '2022-9-27' }]
    }
  },
  methods: {
    //打开弹框
    openDialog() {
      this.dialogVisible = true
    },
    firstStrengthHoverFn(id) {
      this.firstStrength = id
    },
  }
}
</script>
<style scoped lang="less">
@import '@/css/varible.less';

.bg {
  position: relative;
  width: 100%;
  height: 610px;
  background: url(../assets/index/home.jpg);
  background-position: center;
  background-repeat: no-repeat;
  padding-top: @banner;
  box-sizing: border-box;

  .banner {
    display: flex;
    justify-content: space-between;

  }
}

.mr {
  margin-right: 10px;
}


.bottom-btn {
  text-align: center;
  margin-top: 52px;
}

.el-divider__text {
  font-size: 18px;
  color: #60646B;
  background-color: transparent;
}

.el-divider {
  background-color: transparent
}

/* 内容固定1280适配低分辨率屏幕 */
.main-width {
  height: 100%;
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

.subtitle-box {
  padding-top: 40px;
  margin-bottom: 40px;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subtitle {
  font-size: 34px;
  color: #3E3A39;
  font-weight: bold;
  text-align: center;
  margin-top: 40px;

}

.el-divider__text {
  font-size: 22px;
}

/* 八大模块 */
.eight-module {
  position: relative;
  height: 865px;

  .eight-img {
    position: absolute;
    top: 27%;
    left: 25%;
    width: 602px;
    height: 490px;

    background-image: url(@/assets/eightimg/bdgn.svg);

    .dobule-arrow {
      position: absolute;
      top: 25%;
      left: 42%;
      background-image: url(../assets/index/arrow.png);
      width: 90px;
      height: 51px;
      animation: up 2s linear infinite;
    }

    @keyframes up {
      from {
        transform: translateY(90px);
        opacity: 0;
      }

      to {
        transform: translateY(0px)
      }
    }

    .eight-title {
      font-family: PINGFANG MEDIUM;
      font-size: 18px;
      color: #3E3A39;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .eight-title-text {
      font-family: PINGFANG MEDIUM;
      font-size: 16px;
      color: #3E3A39;
      font-weight: 400;
    }


    .eight-text-1 {
      position: absolute;
      text-align: right;
      top: 16%;
      left: -24%;
    }

    .eight-text-2 {
      position: absolute;
      text-align: right;
      top: 38%;
      left: -37%;
    }

    .eight-text-3 {
      position: absolute;
      text-align: right;
      top: 60%;
      left: -50%;
    }

    .eight-text-4 {
      position: absolute;
      text-align: right;
      top: 83%;
      left: -24%;
    }

    .eight-text-5 {
      position: absolute;
      text-align: left;
      top: 16%;
      right: -24%;
    }

    .eight-text-6 {
      position: absolute;
      text-align: left;
      top: 38%;
      right: -48%;
    }

    .eight-text-7 {
      position: absolute;
      text-align: left;
      top: 60%;
      right: -38%;
    }

    .eight-text-8 {
      position: absolute;
      text-align: left;
      top: 82%;
      right: -35%;
    }
  }

  .eight-btn {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }

  .left-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 173px;
    height: 370px;
    background: url(../assets/index/dymb.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .right-img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 274px;
    height: 400px;
    background: url(../assets/index/dymbbf.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

.left-text {
  color: #fff;

  .left-title {
    font-size: 46px;
    font-weight: 700;
    margin-bottom: 32px;
    font-family: "PINGFANG BOLD"
  }

  .left-bottom {
    font-size: 24px;

    div {
      margin-bottom: 20px;
    }

    span {
      margin-left: 18px;
    }
  }
}

/* 核心价值 */
.core-value {
  padding-bottom: 68px;
  background: #F5F9FF;

  .core-title {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: 700;
    width: 80px;
    height: 45px;
  }

  .core-text {
    margin-bottom: 20px;
    font-size: 18px;

    .num-blue {
      color: #0D8EFF;
    }

    .num-title-black {
      color: #3E3A39;
    }

    .core-num {
      margin-right: 15px;
      font-weight: 700;
    }

    .main-color {
      color: #3E3A39;
    }

    .core-num-title {
      font-weight: 700;
    }

    .core-main {
      font-size: 14px;
      line-height: 20px;
      margin-top: 10px;
    }
  }

  .main-width {


    .core-mediumn {
      display: flex;
      justify-content: center;
    }



    .core-left {
      padding-left: 40px;
      padding-right: 100px;
      margin-right: 20px;
      background: #fff;
      box-shadow: 0px 0px 20px 0px rgba(11, 60, 139, 0.06);
      border-radius: 8px;
      width: 580px;
      height: 546px;
      box-sizing: border-box;

      .blue-title {
        color: #0D8EFF;
        border-bottom: 2px solid #0D8EFF;
      }
    }

    .core-right {
      color: #fff;
      padding-left: 100px;
      padding-right: 40px;
      width: 580px;
      height: 546px;
      background-image: linear-gradient(90deg, #16A8FF 0%, #1C52E5 100%);
      box-shadow: 0px 0px 20px 0px rgba(11, 60, 139, 0.06);
      border-radius: 8px;
      box-sizing: border-box;

      .fff-title {
        border-bottom: 2px solid #fff;
      }

    }

    .core-bg {
      width: 174px;
      height: 174px;
      background-color: #dbebff;
      position: absolute;
      top: 45%;
      right: 43%;
      border-radius: 50%;

      @keyframes rotation {

        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }

      }

      .core-vs {
        font-family: PINGFANG MEDIUM;
        font-size: 56px;
        color: #FFFFFF;
        font-weight: 500;
        position: absolute;
        top: 24px;
        left: 24px;
        background-image: linear-gradient(90deg, #16A8FF 0%, #1C52E5 100%);
        width: 126px;
        height: 126px;
        border-radius: 50%;
        background-color: #0063C7;

        img {
          position: absolute;
          top: 83px;
          left: 55px;
          width: 14px;
        }

        .core-vs-text {
          position: absolute;
          top: 16px;
          left: 34px;
        }
      }

      .core-circle {
        animation: rotation 3s linear infinite;
        position: absolute;
        top: 12px;
        right: 11px;
        transform: translate(50%, 20%);
        width: 152px;
        height: 152px;
        background-image: url(../assets/index/circleline.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 3;
      }
    }

  }
}

/* 十大行业背景 */
.ten-industry {
  position: relative;
  padding-bottom: 70px;

  .ten-img {
    height: 362px;

    .img-mask {
      width: 100%;
      height: 100%;
      transition: all .3s;
    }

    .img-mask:hover {
      transform: scale(1.3);
    }

    span {
      position: absolute;
      top: 30px;
      left: 20px;
      font-size: 18px;
      color: #FFFFFF;
      font-weight: 700;
    }

    .ten-top {
      display: flex;
      margin-bottom: 10px;
    }

    .item {
      position: relative;
      width: 300px;
      height: 174px;
      border-radius: 6px;
      overflow: hidden;

    }

    .small-item {
      position: relative;
      width: 214px;
      height: 174px;
      border-radius: 6px;
      overflow: hidden;
      margin-left: 10px;
    }

    .img-bottom {
      display: flex;
    }

    .img-small {
      position: relative;
      width: 214px;
      height: 174px;
      background-size: 100% 100%;
      border-radius: 6px;

    }

    .img-1 {
      position: relative;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      background-image: url(../assets/index/huagong.jpg);

      .img-1-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        background-image: linear-gradient(270deg, rgba(64, 140, 246, 0.00) 0%, #408CF6 99%);
      }
    }

    .img-2 {
      position: relative;
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      border-radius: 6px;
      background-image: url(../assets/index/yejin.jpg);

      .img-2-mask {
        border-radius: 6px;
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(270deg, rgba(103, 70, 0, 0.06) 0%, #B08323 99%);
      }
    }

    .img-3 {
      background-image: url(../assets/index/hospital.jpg);

      .img-3-mask {
        border-radius: 6px;
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(270deg, rgba(7, 101, 205, 0.06) 0%, #0765CD 99%);
      }
    }

    .img-4 {
      background-image: url(../assets/index/jianshe.jpg);

      .img-4-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(-89deg, rgba(0, 128, 182, 0.06) 0%, #0094D3 100%);
        border-radius: 6px;
      }
    }

    .img-5 {
      background-image: url(../assets/index/jiancai.jpg);

      .img-5-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(270deg, rgba(0, 99, 199, 0.06) 0%, #0063C7 99%);
        border-radius: 6px;
      }
    }

    .img-6 {
      position: relative;
      border-radius: 6px;
      width: 214px;
      height: 174px;
      background-size: 100% 100%;
      background-image: url(../assets/index/jixie.jpg);

      .img-6-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(270deg, rgba(51, 77, 156, 0.00) 0%, #334D9C 99%);
        border-radius: 6px;
      }
    }

    .img-7 {

      background-image: url(../assets/index/feimei.jpg);


      .img-7-mask {
        position: absolute;
        width: 100%;
        height: 100%;

        background-image: linear-gradient(270deg, rgba(68, 158, 175, 0.07) 0%, #449EAF 100%);
        border-radius: 6px;
      }

    }

    .img-8 {
      background-image: url(../assets/index/fangzhi.jpg);

      .img-8-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(270deg, rgba(20, 91, 184, 0.06) 0%, #145BB8 99%);
        border-radius: 6px;
      }
    }

    .img-9 {
      background-image: url(../assets/index/smoke.jpg);

      .img-9-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(270deg, rgba(160, 98, 0, 0.06) 0%, #A06200 99%);
        border-radius: 6px;
      }
    }

    .img-10 {
      position: relative;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      background-image: url(../assets/index/gangtie.jpg);

      .img-10-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(-89deg, rgba(22, 139, 160, 0.06) 0%, rgba(22, 139, 160, 0.50) 100%);
        border-radius: 6px;
      }
    }
  }
}

/* 预约使用 */
.enter-name {
  height: 270px;
  background-image: url(../assets/index/bluebj.jpg);
  background-size: 100% 100%;
}

/* 典型客户 */
::v-deep .el-carousel__container {
  width: 1240px;
  margin: 0 auto
}

.typical-customer {
  height: 517px;
  background-image: url(../assets/index/customerbj.jpg);
  background-size: 100% 100%;

  .carousel-box {
    display: grid;
    grid-template-columns: repeat(5, 221px);
    column-gap: 30px;
  }

  ::v-deep .el-carousel__arrow {
    font-size: 16px;
  }

  .customer-box {
    img {
      width: 100%;
    }
  }
}

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* 品牌实力 */
.brand-strength {
  height: 712px;

  .icon-position {
    position: absolute;
    top: 190px;
    left: 20px;
  }

  .text-position {
    font-family: PINGFANG MEDIUM;
    position: absolute;
    top: 250px;
    left: 20px;
    font-size: 22px;
    color: #FFFFFF;
    font-weight: 700;
  }

  .four-strength {
    position: relative;
    display: flex;
    justify-content: center;

    .mask-icon {
      margin-left: 20px;
      margin-bottom: 10px;
    }

    .mask-text1 {
      margin-left: 20px;
      font-family: PINGFANG MEDIUM;
      font-size: 22px;
      color: #FFFFFF;
      font-weight: 700;
      height: 40px;
    }

    .mask-text2 {
      margin-left: 20px;
      width: 380px;
      font-family: PINGFANG MEDIUM;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 26px;
      font-weight: 400;
    }

    .active {
      transition: all .4s ease .1s;
      width: 430px;
      height: 374px;
      background-image: url(../assets/index/sl1.jpg);
      background-size: cover;
      position: relative;
      border-radius: 8px;

      .icondelay {
        position: absolute;
        transition: all .4s ease .1s;
        top: 60px;
      }

      .text1delay {
        position: absolute;
        transition: all .4s ease .13s;
        top: 120px;
      }

      .text2delay {
        position: absolute;
        transition: all .4s ease .16s;
        top: 170px;
      }
    }

    .first-strength {
      flex-shrink: 0;
      position: relative;
      width: 248px;
      height: 374px;
      transition: all .4s ease .1s;
      background-size: cover;
      border-radius: 8px;

      .icondelay {
        position: absolute;
        transition: all .4s ease .1s;
        top: 210px;
      }

      .text1delay {
        position: absolute;
        transition: all .4s ease .14s;
        top: 280px;
      }

      .text2delay {
        position: absolute;
        transition: all .4s ease .16s;
        top: 370px;
      }
    }

    .first-mask {
      background: rgba(0, 9, 24, 0.62);
      border-radius: 8px;
      overflow: hidden;
    }


  }
}

/* 底部文章列表 */
.artical-list {
  height: 612px;
  background: #F5F9FF;

  .artical-layout {
    margin: 0 auto;
    width: 1285px;
    display: flex;
    justify-content: space-between;
    padding-top: 68px
  }

  .artical-img {
    width: auto;
    height: 390px;

    .artical-img-size {
      cursor: pointer;
      float: left;
      width: 288px;
      height: 184px;
      background-size: 100% 100%;
      position: relative;
      margin: 0 8px 16px 8px;
      box-sizing: border-box;
      transition: all 0.3s;
      border-radius: 8px;
    }

    .artical-img-size:hover {
      transform: scale(1.1);
    }

    .artical-img-mask {
      color: #fff;
      text-align: center;
      font-size: 16px;
      line-height: 44px;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 44px;
      width: 100%;
      background: rgba(0, 0, 0, 0.60);
      border-radius: 8px;

      span {
        display: inline-block;
        width: 80%;
        height: 33px;
        overflow: hidden;
      }
    }
  }

  .artical-right {
    width: 75%
  }
}

.box-card {
  width: 480px
}

.phone {
  margin-bottom: 10px;
}

.action {
  width: 80%
}

.card-title {
  text-align: left;
  height: 50px
}

.auth-code {
  color: rgb(51, 39, 146);
  font-size: 14px;
  transform: translateY(26%);
}

::v-deep .input-with-select .el-input-group__prepend {
  background-color: #fff;
}

::v-deep .el-input-group__append {
  background-color: #fff;
}

::v-deep .el-carousel__arrow--left {
  left: -30px;
}

::v-deep .el-carousel__arrow--right {
  right: -30px;
}

::v-deep .el-carousel__arrow {
  top: 40%;
  border-radius: 0%;
  width: 30px;
  height: 70px;
  border-radius: 4px;

}

::v-deep .el-carousel__arrow:hover {
  background-image: linear-gradient(90deg, #27AFFF 0%, #1F66F7 100%);
}

.carousel-img {
  width: 1110px !important;
  display: flex;
  justify-content: space-between;
}</style>
