<!-- 产品功能 -->
<template>
    <div>
        <div class="pc">
            <pageHeaderPC></pageHeaderPC>
            <div class="header-img">
                <div class="top-header">
                    <div class="header-left">
                        <h2 class="header-title">金企盾双重预防管理系统</h2>
                        <div class="header-text">
                            <img src="../assets/index/duigou.png" alt="">
                            <span>双重安全保障</span>
                        </div>
                        <div class="header-text">
                            <img src="../assets/index/duigou.png" alt="">
                            <span>源头治理</span>
                        </div>
                        <div class="header-text">
                            <img src="../assets/index/duigou.png" alt="">
                            <span>消除隐患</span>
                        </div>
                        <div class="header-text">
                            <img src="../assets/index/duigou.png" alt="">
                            <span>守护企业安全</span>
                        </div>
                    </div>
                    <div class="header-right">
                        <registryForm></registryForm>
                    </div>
                </div>

                <div class="header-card" v-if="false">
                    <span class="down">政策下载：</span>
                    <div class="twoLawbox">
                        <div :title="item.title" @click="openDialog" class="downtext" v-for="item in twoLaw" :key="item.id">
                            {{ item.title }}</div>
                    </div>

                </div>
            </div>
            <div class="jqd-what">
                <div class="subtitle-box mt" style="margin-bottom:35px">金企盾双重预防机制管理系统是什么？</div>
                <div class="text">
                    金企盾通过构建风险分级管控与隐患排查治理体系，形成预先辨识风险，周期排查隐患，及时发现与治理的管理方式，遏制事故发生。在事故之前构建两道防护墙，将风险控制在隐患形成之前，将隐患消除在事故发生之前，实现事前预防，守护企业生命财产安全。
                </div>
                <div class="text">
                    系统包含企业安全一张图、风险分级管控、隐患排查治理等八大模块，功能满足国家政策要求。预留数据接口，满足第三方系统集成，避免形成数据孤岛。支持横向拓展及功能二次开发，满足企业长期使用要求，为企业安全保驾护航，是企业安全管理的好伙伴！
                </div>
                <div class="btn-center">
                    <el-button @click="openDialog" class="liner-btn" type="primary">预约使用</el-button>
                </div>
            </div>
            <!-- 安全生产一张图 -->
            <div class="safe-oneimg">
                <div class="subtitle-box">八大功能落实双重预防机制建设</div>
                <div class="img-box">
                    <img src="../assets/index/gnt.png" alt="功能图">
                </div>
                <div class="subtitle-box pt40">功能描述</div>
                <div class="feature-tabs">
                    <span :id="item.id" @click="handleMouseEnter" v-for="item in featureList" :key="item.id">{{ item.content
                    }}
                        <i :class="tabflag == item.id ? 'bd' : ''"></i>
                    </span>
                    <div></div>
                </div>
                <div v-for="item in eightList" :key="item.id">
                    <div v-if="item.isShow" class="feature-content">
                        <div class="left-content">
                            <div style="margin-bottom:22px">
                                <span class="num-title">{{ item.numTitle }}</span>
                                <span class="title-text">{{ item.titleText }}</span>
                            </div>
                            <div class="sub-title">
                                {{ item.subtitle }}
                            </div>
                            <div class="line">
                                <div class="small-line"></div>
                            </div>
                            <div class="ture-text">
                                <div>
                                    <img :src="item.imgsrc" alt="">
                                    <span>{{ item.truetext1 }}
                                    </span>
                                </div>
                                <div>
                                    <img :src="item.imgsrc" alt="">
                                    <span>{{ item.truetext2 }}</span>
                                </div>
                                <div v-if="item.truetext3">
                                    <img :src="item.imgsrc" alt="">
                                    <span>{{ item.truetext3 }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="right-content">
                            <img :src="item.rightimg" alt="">
                        </div>
                    </div>

                </div>
                <div class="btn-center">
                    <el-button @click="openDialog" class="liner-btn" type="primary">预约使用</el-button>
                </div>
            </div>
            <!-- 五大价值 -->
            <div class="five-value">
                <div class="subtitle-box" style="color:#fff">五大价值</div>
                <div class="five-img">
                    <div class="value-box">
                        <span>01</span>
                        <img src="../assets/index/companysafe.png" alt="">
                        <div class="value-text">保障企业安全</div>
                        <div class="value-bg"></div>
                    </div>
                    <div class="value-box">
                        <span>02</span>
                        <img src="../assets/index/cpsj.png" alt="">
                        <div class="value-text">人性化产品设计</div>
                        <div class="value-bg"></div>
                    </div>
                    <div class="value-box">
                        <span>03</span>
                        <img src="../assets/index/lowchengben.png" alt="">
                        <div class="value-text">降低运维成本</div>
                        <div class="value-bg"></div>
                    </div>
                    <div class="value-box">
                        <span>04</span>
                        <img src="../assets/index/allman.png" alt="">
                        <div class="value-text">促进全员参与</div>
                        <div class="value-bg"></div>
                    </div>
                    <div class="value-box">
                        <span>05</span>
                        <img src="../assets/index/zfjg.png" alt="">
                        <div class="value-text">符合政府监管</div>
                        <div class="value-bg"></div>
                    </div>
                </div>
            </div>
            <!-- 典型客户 -->
            <div class="typical-customer">
                <div class="subtitle-box">典型客户</div>
                <el-carousel indicator-position="none" arrow="never">
                    <el-carousel-item v-for="(item, index) in beeList" :key="index">
                        <div class="bee-box">
                            <img :src="item.url" alt="">
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <!-- <div class="customer-img"></div> -->
            </div>
            <!-- 预约使用 -->
            <div class="free-exprience" v-if="false">
                <div class="free-box">
                    <div class="free-left"></div>
                    <div class="free-right">
                        <registryForm></registryForm>
                    </div>
                </div>
            </div>
            <!-- 底部导航 -->
            <foot></foot>
            <!-- 弹框表单 -->
            <!-- <iframeBox :dialogVisible.sync="dialogVisible"></iframeBox> -->

            <formDialog :dialogVisible.sync="dialogVisible" ref="formRef"></formDialog>
        </div>
        <div class="mobile">
            <mobileFeature></mobileFeature>
        </div>
    </div>
</template>
<script>
import pageHeaderPC from '../components/navigationPC.vue'
import foot from '@/components/FootNavBar.vue'

import formDialog from '@/components/dialogForm.vue'
import registryForm from '@/components/TopForm.vue'
import { getContentInfo } from '@/utils/api'
import mobileFeature from './mobile/mobileFeature.vue'
export default {
    name: 'productFeature',
    components: {
        registryForm, foot, formDialog, pageHeaderPC, mobileFeature
    },
    created() {
        this.getContentInfoFn()
    },
    mounted() {
        this.$refs.formRef.$refs.diaRef.rendered = true
    },
    data() {
        return {
            dialogVisible: false,
            tabflag: 1,//tab span 底部border
            beeList: [
                { url: require('@/assets/customerLogo/customer1.svg'), id: 1 },
                { url: require('@/assets/customerLogo/customer2.svg'), id: 2 },
            ],//丰巢图客户
            featureList: [
                { id: 1, content: '风险分级管控' },
                { id: 2, content: '隐患排查治理' },
                { id: 3, content: '随手拍' },
                { id: 4, content: '奖惩管理' },
                { id: 5, content: '风险告知' },
                { id: 6, content: '通知待办' },
                { id: 7, content: '数据上报' },
                { id: 8, content: '企业安全一张图' },
            ],
            eightList: [{
                id: 1,
                isShow: true,
                numTitle: '01',
                titleText: '风险分级管控',
                subtitle: '建立风险管控清单,责任划分明确到人',
                imgsrc: require('@/assets/indestry/duigou.png'),
                truetext1: '将每一个风险点均划分对应的负责人',
                truetext2: '针对不同风险等级的风险点采取针对性管控措施',
                truetext3: '有效预防事故发生',
                rightimg: require('@/assets/eightimg/fxfj1.png')
            },
            {
                id: 2,
                isShow: false,
                numTitle: '02',
                titleText: '隐患排查治理',
                subtitle: '及时发现隐患，源头遏制事故',
                imgsrc: require('@/assets/indestry/duigou.png'),
                truetext1: '针对风险清单周期性排查',
                truetext2: '拥有超期预警提示',
                truetext3: '促进及时发现隐患，快速解决问题',
                rightimg: require('@/assets/eightimg/yhpc2.png')
            },
            {
                id: 3,
                isShow: false,
                numTitle: '03',
                titleText: '随手拍',
                subtitle: '隐患及时上报，全员上报隐患',
                imgsrc: require('@/assets/indestry/duigou.png'),
                truetext1: '建立普通员工上报隐患的通道',
                truetext2: '发现隐患随时上报',
                truetext3: '让隐患无处可藏',
                rightimg: require('@/assets/eightimg/ssp3.png')
            },
            {
                id: 4,
                isShow: false,
                numTitle: '04',
                titleText: '奖惩管理',
                subtitle: '明确奖惩机制，鼓励全员参与',
                imgsrc: require('@/assets/indestry/duigou.png'),
                truetext1: '规范奖惩政策',
                truetext2: '鼓励全员积极排查、消除隐患',
                truetext3: '促进双防制度落实',
                rightimg: require('@/assets/eightimg/jcgl4.png')
            },
            {
                id: 5,
                isShow: false,
                numTitle: '05',
                titleText: '风险告知',
                subtitle: '明示风险隐患，指导应急处置',
                imgsrc: require('@/assets/indestry/duigou.png'),
                truetext1: '明示企业所有潜在风险点',
                truetext2: '告知员工针对不同风险点的响应应急',
                rightimg: require('@/assets/eightimg/fxgz5.png')
            },
            {
                id: 6,
                isShow: false,
                numTitle: '06',
                titleText: '通知待办',
                subtitle: '智能区分待办已办，超期未办自动预警',
                imgsrc: require('@/assets/indestry/duigou.png'),
                truetext1: '事事有通知，超期有预警，事事不遗漏',
                truetext2: '促进员工按时执行工作任务，提高隐患排查整改效率',
                rightimg: require('@/assets/eightimg/tzdb6.png')
            },
            {
                id: 7,
                isShow: false,
                numTitle: '07',
                titleText: '数据上报',
                subtitle: '预留数据接口，自主审核上报',
                imgsrc: require('@/assets/indestry/duigou.png'),
                truetext1: '预留数据上报接口',
                truetext2: '可支持企业审核风险、隐患、排查数据后自主上报政府监管平台和',
                rightimg: require('@/assets/eightimg/sjsb7.png')
            },
            {
                id: 8,
                isShow: false,
                numTitle: '08',
                titleText: '企业安全一张图',
                subtitle: '风险隐患数据统计，风险空间动态展示',
                imgsrc: require('@/assets/indestry/duigou.png'),
                truetext1: '风险数据与厂区地图深度融合，支持跳转调阅，一图即可查看全厂风险数据',
                truetext2: '提升全厂安全指挥能力，提升应急管理能力',
                rightimg: require('@/assets/eightimg/qyaq8.png')
            },
            ],
            twoLaw: [

            ],//两排两列
        }
    },
    methods: {
        autoScroll(dom, interval) {
            /*  debugger */
            var parentDom = document.querySelector(dom) //获取滚动元素
            setInterval(() => {
                //判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
                if (parentDom.scrollTop + parentDom.clientHeight === parentDom.scrollHeight) {
                    parentDom.scrollTop = 0
                } else {
                    parentDom.scrollTop++ // 元素自增距离顶部
                }
            }, interval)
        },
        getContentInfoFn() {
            let that = this
            let params = {
                channelId: 278,
                pageNo: 1,
            }
            getContentInfo(params).then(res => {
                if (res.status == 200) {
                    if (res.data.total > 0) {
                        that.twoLaw = res.data.content
                    }
                } else {
                    console.log('暂无数据');
                }
            }).catch(err => {
                console.log('请求失败');
            })
        },
        //打开弹框
        openDialog() {
            this.dialogVisible = true
        },
        /* loopFn(){
           clearInterval(this.timeObj)
            this.timeObj = setInterval(()=>{
                this.handleMouseEnter(this.loopObj)
                this.loopObj.target.id = this.loopObj.target.id + 1
                
            },2000)
            
        }, */
        handleMouseEnter(e) {
            let that = this
            that.tabflag = e.target.id
            that.eightList.forEach(item => {
                item.isShow = false
            })
            switch (e.target.id) {
                case '1':
                    that.eightList[0].isShow = true
                    break;
                case '2':
                    that.eightList[1].isShow = true
                    break;
                case '3':
                    that.eightList[2].isShow = true
                    break;
                case '4':
                    that.eightList[3].isShow = true
                    break;
                case '5':
                    that.eightList[4].isShow = true
                    break;
                case '6':
                    that.eightList[5].isShow = true
                    break;
                case '7':
                    that.eightList[6].isShow = true
                    break;
                case '8':
                    that.eightList[7].isShow = true
                    break;
                default:
                    break;
            }
        },
    }
}

</script>
<style scoped lang="less">
@import '@/css/varible.less';
@import url(./mobile/css/mobileCss.css);

.mt {
    margin-top: 66px
}

.pt40 {
    padding-top: 60px;
}

.header-img {
    position: relative;
    height: 610px;
    background-position: center;
    background-image: url(../assets/index/featureaBanner.jpg);
    /*  margin-top: 64px; */
    box-sizing: border-box;

    .top-header {
        width: 1200px;
        margin: auto;
    }

    .header-left {
        margin-top: @banner;
        float: left;

    }

    .header-right {
        margin-top: @banner;
        float: right;
    }

    .header-title {
        //font-family: SourceHanSansCN-Bold;
        font-size: 46px;
        color: #FFFFFF;
        font-weight: 700;
        margin-bottom: 40px;
    }

    .header-text {
        //font-family: SourceHanSansCN-Regular;
        font-size: 24px;
        color: #FFFFFF;
        line-height: 44px;
        font-weight: 400;

        img {
            margin-right: 20px;
        }
    }

    .header-card {
        box-shadow: 0px 0px 30px 0px rgba(11, 60, 139, 0.08);
        border-radius: 6px;
        position: absolute;
        top: 101%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 55px;
        width: 1200px;
        padding: 12px 90px 12px 60px;
        box-sizing: border-box;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .twoLawbox::-webkit-scrollbar {
            width: 0px;
        }

        .twoLawbox {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            overflow-y: auto;
            width: 90%;
            height: 30px;


            div {
                display: inline-block;
                width: 50%;
                height: 32px;
                line-height: 32px;
            }
        }

        .downtext {
            cursor: pointer;
            font-size: 16px;
            color: #666;
            font-weight: 400;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            /* white-space: nowrap;
            text-overflow:ellipsis */

        }

        .downtext:hover {
            color: #0D8EFF
        }

        .down {
            display: inline-block;
            width: 80px;
            //font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            color: #EE6D00;
            font-weight: 400;
            line-height: 58px;
        }
    }
}

.jqd-what {
    padding-bottom: 70px;

    .text {
        width: 1200px;
        margin: 0 auto;
        // font-family: SourceHanSansCN-Regular;
        font-size: 16px;
        color: #3E3A39;
        line-height: 30px;
        font-weight: 400;
        margin-bottom: 26px;
    }
}

.safe-oneimg {
    width: 100%;
    box-sizing: border-box;
    padding-top: 66px;
    padding-bottom: 66px;
    background-image: url('../assets/index/bdgnbj.jpg');
    background-position: center;

    .img-box {
        text-align: center;
    }

    .feature-tabs {
        height: 25px;
        display: flex;
        width: 1200px;
        padding: 0 20px;
        box-sizing: border-box;
        justify-content: space-between;
        margin: 0 auto;
        margin-bottom: 70px;
        position: relative;

        div {
            position: absolute;
            bottom: -22px;
            left: 0;
            width: 1200px;
            height: 1px;

            background: #E7ECF0;
            border-radius: 2px;
        }

        span {
            position: relative;
            //font-family: SourceHanSansCN-Medium;
            font-size: 18px;
            color: #3E3A39;
            font-weight: 500;
            height: 30px;
            display: inline-block;
            cursor: pointer;

        }

        i {
            position: absolute;
            bottom: -16px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 4px;
            background-color: #3d91ff;
            border-radius: 2px;
        }

        .bd {
            width: 140px;

        }
    }

    .feature-content {

        width: 1200px;
        padding: 0 20px;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-content {
            flex-shrink: 0;

            .line {
                position: relative;
                width: 100%;
                height: 1px;
                background: #E7ECF0;
                margin-bottom: 42px;

                .small-line {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 3px;
                    width: 30px;
                    background: #CACFD3;
                }
            }
        }

        .num-title {
            //font-family: SourceHanSansCN-Bold;
            font-size: 24px;
            color: #0D8EFF;
            font-weight: 700;
            margin-right: 10px;
        }

        .title-text {
            //font-family: SourceHanSansCN-Bold;
            font-size: 24px;
            color: #3E3A39;
            font-weight: 700;

        }

        .sub-title {
            margin-bottom: 33px;
            //font-family: SourceHanSansCN-Light;
            font-size: 18px;
            color: #3E3A39;
            font-weight: 200;
        }

        .ture-text {
            //font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            color: #3E3A39;
            font-weight: 400;

            img {
                margin-right: 15px;
                vertical-align: top;
            }

            div {
                margin-bottom: 32px;
            }
        }

        @keyframes dou {
            0% {
                transform: translateX(10px);
            }

            100% {
                transform: translateX(0);

            }
        }

        .right-content {
            animation: .2s linear dou;

            img {
                width: 600px;
                height: 420px;
            }
        }

    }
}

/* 五大价值 */
.five-value {
    padding-top: 66px;
    height: 540px;
    background-image: url(../assets/index/fivevaluebj.jpg);
    background-size: 100% 100%;

    span {
        position: absolute;
        top: 4%;
        right: 10%;
        //font-family: SourceHanSansCN-Bold;
        font-size: 44px;
        color: #E4EFF6;
        letter-spacing: -1.85px;
        text-align: center;
        font-weight: 700;
        opacity: 0.5;
    }

    .value-bg {
        transition: all .2s ease;
        background-image: url(../assets/index/default.png);
        background-size: 100% 100%;
        width: 171px;
        height: 65px;
        position: absolute;
        bottom: 8%;
        left: 10%;
    }

    .value-box:hover .value-bg {
        bottom: 10%;
        background-image: url(../assets/index/selected.png);
    }

    .value-box:hover {
        transform: translateY(-15px);
    }

    .value-text {
        position: absolute;
        bottom: 30%;
        left: 25%;
        //font-family: SourceHanSansCN-Bold;
        font-size: 20px;
        color: #3E3A39;
        text-align: center;
        font-weight: 700;
    }

    .value-box {
        position: relative;
        transition: all .5s ease;
        width: 226px;
        height: 300px;
        background: #FFFFFF;
        border-radius: 6px;

        img {
            position: absolute;
            top: 22%;
            left: 29%;
        }
    }

    .five-img {
        margin: 0 auto;
        width: 1200px;
        display: flex;
        justify-content: space-between;
    }
}

/* 典型客户 */
.typical-customer {
    box-sizing: border-box;
    padding-top: 60px;
    padding-bottom: 60px;

    .bee-box {
        width: 1200px;

        img {
            width: 100%;
        }
    }
}

::v-deep .el-carousel__container {
    width: 1205px;
    height: 370px;
    margin: 0 auto;


}

::v-deep .el-carousel__arrow {
    font-size: 16px;
}

::v-deep .el-carousel__arrow--left {
    left: -50px;
}

::v-deep .el-carousel__arrow--right {
    right: -50px;
}

::v-deep .el-carousel__arrow {
    top: 40%;
    border-radius: 0%;
    width: 30px;
    height: 70px;
    border-radius: 4px;

}

::v-deep .el-carousel__arrow:hover {
    background-image: linear-gradient(90deg, #27AFFF 0%, #1F66F7 100%);
}

/* 预约使用 */
.free-exprience {
    padding-top: 60px;
    box-sizing: border-box;
    height: 507px;
    background: #F5F9FF;
    background-image: url('@/assets/index/freebg.jpg');
    width: 100%;
    background-size: cover;

    .free-box {
        width: 1200px;
        margin: auto;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .free-left {

            height: 446px;
            width: 785px;
            background-image: url(../assets/index/free-logo.png);
            background-size: cover
        }

        .free-right {

            margin-right: 10px;
        }
    }

}
</style>
