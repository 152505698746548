<template>
    <div class="product-box">
        <!-- 产品功能banner -->
        <div class="product-banner">
            <div class=" product-title">{{ productTitle }}</div>
            <div class="banner-disc">
                <div v-for="item in discList" :key="item.id" class="disc-item">
                    <img :src="item.imgSrc" />
                    <span>{{ item.txt }}</span>
                </div>
            </div>
        </div>
        <!-- 功能描述 -->
        <div class="product-desc">
            <div class="left-bg"></div>
            <div class="right-bg"></div>
            <div class="product-txt">{{ productDesc }}</div>
        </div>
        <!-- 八大功能落实 -->
        <div class="eight-feature-wrapper">
            <div class="eight-feature-title">{{ eightTitle }}</div>
            <img src="@/assets/mobile/product-img/feature-banner2.png" class="eight-banner"/>
        </div>
        <!--功能描述tab -->
        <div class="feature-tabs-wrapper">
            <div class="eight-desc-title">{{ featureTitle }}</div>
            <van-tabs v-model="active" @click="tabClick" swipeable @change="tabsChange">
                <van-tab v-for="item in featureList" :title="item.title" :key="item.id">
                    <div class="tab-wrapper-desc">
                        <div class="tab-box" v-for="item in tabListComputed" :key="item.id">
                            <div class="img-box">
                                <img class="tab-banner" :src="item.url" />
                            </div>
                            <div class="tab-desc">
                                <div class="num-box mb-15">
                                    <span class="num">{{ item.num }}</span>
                                    <span class="num-title">{{ item.numTitle }}</span>
                                </div>
                                <div class="sub-title mb-15">{{ item.subTitle }}</div>
                                <div class="sub-line mb-15">
                                    <div class="small-line"></div>
                                </div>
                                <div class="desc-txt mb-15" v-for="i in item.desc" :key="i.id">
                                    <img class="right-icon" :src="i.iconUrl" />
                                    <span class="right-txt">{{ i.descTitle }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <!-- 五大价值 -->
        <fiveVale></fiveVale>
        <!-- 典型客户 -->
        <typicalCustomer></typicalCustomer>
    </div>
</template>
<script>
import fiveVale from './fiveValue.vue'
import typicalCustomer from './typicalCustomer.vue'
export default {
    name: '',
    components: { fiveVale, typicalCustomer },
    created() {

    },
    computed: {
        tabListComputed: function () {
            return this.tabCopyList
        }
    },
    data() {
        return {
            productTitle: '金企盾双重预防机制管理系统',
            eightTitle: '八大功能落实双重预防机制建设',
            featureTitle: '功能描述',
            rightIcon: require('@/assets/mobile/product-img/right-icon.svg'),
            active: '',
            discList: [
                { imgSrc: require('@/assets/index/duigou.png'), txt: '双重安全保障', id: 1 },
                { imgSrc: require('@/assets/index/duigou.png'), txt: '源头治理', id: 2 },
                { imgSrc: require('@/assets/index/duigou.png'), txt: '消除隐患', id: 3 },
                { imgSrc: require('@/assets/index/duigou.png'), txt: '守护企业安全', id: 4 },
            ],
            productDesc: '金企盾通过构建风险分级管控与隐患排查治理体系，形成预先辨识风险，周期排查隐患，及时发现与治理的管理方式，遏制事故发生。在事故之前构建两道防护墙，将风险控制在隐患形成之前，将隐患消除在事故发生之前，实现事前预防，守护企业生命财产安全。',
            featureList: [
                { title: '风险分级管控', id: 0 },
                { title: '隐患排查治理', id: 1 },
                { title: '随手拍', id: 2 },
                { title: '奖惩管理', id: 3 },
                { title: '风险告知', id: 4 },
                { title: '通知待办', id: 5 },
                { title: '数据上报', id: 6 },
                { title: '企业安全一张图', id: 7 },
            ],
            tabCopyList: [{ url: require('@/assets/mobile/product-img/1risk-control.png'), num: '01', numTitle: '风险分级管控', subTitle: '建立风险管控清单，责任划分明确到人', desc: [{ iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '将每一个风险点均划分对应的负责人' }, { iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '针对不同风险等级的风险点采取针对性管控措施' }, { iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '有效预防事故发生' }] },],
            tabList: [
                { url: require('@/assets/mobile/product-img/1risk-control.png'), num: '01', numTitle: '风险分级管控', subTitle: '建立风险管控清单，责任划分明确到人', desc: [{ iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '将每一个风险点均划分对应的负责人' }, { iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '针对不同风险等级的风险点采取针对性管控措施' }, { iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '有效预防事故发生' }] },

                { url: require('@/assets/mobile/product-img/2hidden-trouble-shoot.png'), num: '02', numTitle: '隐患排查治理', subTitle: '及时发现隐患，源头遏制事故', desc: [{ iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '针对风险清单周期性排查' }, { iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '拥有超期预警提示' }, { iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '促进及时发现隐患，快速解决问题' }] },

                { url: require('@/assets/mobile/product-img/3conven-photo.png'), num: '03', numTitle: '随手拍', subTitle: '隐患及时上报，全员上报隐患', desc: [{ iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '建立普通员工上报隐患的通道' }, { iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '发现隐患随时上报' }, { iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '让隐患无处可藏' }] },

                { url: require('@/assets/mobile/product-img/4reward-punish.png'), num: '04', numTitle: '奖惩管理', subTitle: '明确奖惩机制，鼓励全员参与', desc: [{ iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '规范奖惩政策' }, { iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '鼓励全员积极排查、消除隐患' }, { iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '促进双防制度落实' }] },

                { url: require('@/assets/mobile/product-img/5risk-notice.png'), num: '05', numTitle: '风险告知', subTitle: '明示风险隐患，指导应急处置', desc: [{ iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '明示企业所有潜在风险点' }, { iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '告知员工针对不同风险点的响应应急' }] },

                { url: require('@/assets/mobile/product-img/6notice-todo.png'), num: '06', numTitle: '通知待办', subTitle: '智能区分待办已办，超期未办自动预警', desc: [{ iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '事事有通知，超期有预警，事事不遗漏' }, { iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '促进员工按时执行工作任务，提高隐患排查整改效率' }] },

                { url: require('@/assets/mobile/product-img/7data-report.png'), num: '07', numTitle: '数据上报', subTitle: '预留数据接口，自主审核上报', desc: [{ iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '预留数据上报接口' }, { iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '可支持企业审核风险、隐患、排查数据后自主上报政府监管平台和' }] },

                { url: require('@/assets/mobile/product-img/8safe-pic.png'), num: '08', numTitle: '企业安全一张图', subTitle: '风险隐患数据统计，风险空间动态展示', desc: [{ iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '风险数据与厂区地图深度融合，支持跳转调阅，一图即可查看全厂风险数据' }, { iconUrl: require('@/assets/mobile/product-img/right-icon.svg'), descTitle: '提升全厂安全指挥能力，提升应急管理能力' }] },
            ]
        }
    },
    methods: {
        tabClick(name, title) {
            this.tabCopyList = this.tabList.filter(item => item.numTitle == title)
        },
        tabsChange(name,title){
            this.tabCopyList = this.tabList.filter(item => item.numTitle == title)
           
        }
    }
}

</script>
<style scoped lang="less">
@import url(../css/mobileCss.css);

.product-box {
    padding-top: 56px;

    .product-banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        height: 350px;
        background-image: url(@/assets/mobile/product-img/product-banner2.png);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        padding-top: var(--banner-pad);
        box-sizing: border-box;
        color: #fff;
        .product-title {
            font-size: 1.5rem;
            text-align: center;
            //padding: 0px 0 20px 0;
            font-weight: 700;
            box-sizing: border-box;
        }

        

        .banner-disc {
            display: flex;
            flex-wrap: wrap;
            row-gap: 20px;
            position: absolute;
            bottom: 20px;
            width: 300px;
            margin: 0 auto;
            
        }

        .banner-disc .disc-item {
            width: 50%;
            img{
                margin-right: 5px;
            }
        }
    }

    /* banner地下文字部分 */
    .product-desc {
        position: relative;
        padding: 30px 18px 20px;
        font-size: 14px;

        .left-bg {
            position: absolute;
            top: 0;
            left: 0;
            height: 44px;
            width: 114px;
            background-image: url(@/assets/mobile/product-img/white-circle-left2.png);
            background-repeat: no-repeat;
            background-size: cover;
        }

        .right-bg {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 31px;
            width: 79px;
            background-image: url(@/assets/mobile/product-img/white-circle-right2.png);
            background-repeat: no-repeat;
            background-size: cover;
        }

        .product-txt {
            line-height: 1.7;
        }

    }
}

/* 八大功能 */
.eight-feature-wrapper {
    background-color: rgba(245, 249, 255, 1);
    position: relative;

    .eight-feature-title {
        font-size: 1.5rem;
        text-align: center;
        padding: 43px 0 20px 0;
        font-weight: 700;
       
    }

    .eight-banner {
       
        position: relative;
        
        width: 100%;
        height: auto;

        /* background-image: url(@/assets/mobile/product-img/feature-banner2.png);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center; */
    }
}

/* 功能描述 */
.feature-tabs-wrapper {
    background-color: rgba(245, 249, 255, 1);
    padding-bottom: 27px;
    .eight-desc-title{
        font-size: 1.5rem;
        text-align: center;
        padding: 43px 0 20px 0;
        font-weight: 700;
        box-sizing: border-box;
    }
    ::v-deep .van-tabs--line .van-tabs__wrap{
        border-bottom: 1px solid rgba(228, 231, 236, 1);
        margin-bottom: 15px;
    }
    ::v-deep .van-tabs__line {
        width: 80px;
        background-color: rgba(61, 118, 246, 1);
    }

    ::v-deep .van-tab__text {
        font-weight: 700;
    }
    ::v-deep .van-tabs__nav{
        background-color: rgba(245, 249, 255, 1);

    }
    ::v-deep .van-tabs__wrap {
        //padding: 0 10px;
    }

    .tab-box {
        position: relative;
        display: flex;
        flex-direction: column;

        .img-box {
            text-align: center;
        }

        .tab-banner {
            width: 345px;
            height: 242px;
        }

        .tab-desc {
            padding-left: 15px;
            padding-right: 15px;
            .num-box {
                font-weight: 700;

                .num {
                    font-size: 14px;
                    color: rgba(13, 142, 255, 1);
                    margin-right: 11px;
                }

                .num-title {
                    font-size: 15px;
                    color: rgba(62, 58, 57, 1);

                }
            }

            .sub-title {
                font-size: 13px;
                color: rgba(102, 102, 102, 1);
                margin-left: 26px;
            }

            .sub-line {
                height: 1px;
                background: #D1DFF5;
                margin-left: 26px;

                .small-line {
                    height: 1px;
                    width: 25px;
                    background: rgba(13, 142, 255, 1);
                }
            }

            .desc-txt {
                display: flex;
                font-size: 14px;
                color: #2E2E2E;

                .right-icon {
                    width: 15px;
                    height: 14px;
                    margin-right: 11px;
                }
                .right-txt{
                    line-height: 1.5;
                }
            }
        }
    }

    .mb-15 {
        margin-bottom: 15px;
    }
}</style>