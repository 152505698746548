<template>
  <div>
      <page-header></page-header>
      <feature-component></feature-component>
      <page-foot></page-foot>
      <pop></pop>
  </div>
</template>
<script>
import pageHeader from '@/components/pageHeader.vue';
import pageFoot from '@/components/pageFoot.vue';
import pop from './pop.vue'
import featureComponent from './components/featureComponent'
export default {
  name: '',
  components: { pageHeader, pageFoot, pop, featureComponent },
  created() {

  },
  data() {
      return {

      }
  },
  methods: {

  }
}

</script>
<style scoped></style>