<template>
  <div>
    <!-- 底部list -->
    <div class="artical-list">
      <div class="main-width ">
        <div class="artical-layout">
          <div class="left-box">
            <div class="artical-img" v-for="item in footImgList" :key="item.id" @click="detailFn(item)">
              <div class="artical-img-size" :style="{ backgroundImage: `url(${imgURL(item.typeImg)})` }">
              </div>
              <div class="artical-img-mask">
                  <span>{{ item.title }}</span>
                </div>
            </div>
          </div>
          <div class="artical-text artical-right">
            <ul>
              <li v-for="item in footTextList" :key="item.id" @click="detailFn(item)">
                <div style="display:flex;justify-content: space-between;width:540px;">
                  <span class="text-1">{{ item.title }}</span>
                  <span style="font-size:14px;color: #9CA7B1;font-weight: 400;">{{ item.releaseDate?.slice(0, 10)
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="bottom-btn">
          <el-button class="liner-btn" type="primary" @click="openDialog">查看更多</el-button>
        </div>

      </div>
    </div>
    <!-- form弹框 -->
    <!-- <formDialog :dialogVisible.sync="dialogVisible"></formDialog> -->
  </div>
</template>
<script>
import { getContentInfo } from '@/utils/api'
export default {
  components: {  },
  created() {
    this.getContentInfoFn()
  },
  data() {
    return {
      imgURL(data, type) {
        return this.$imgURL + data;
      },
      dialogVisible: false,
      channelId: '277',
      footImgList: [],
      footTextList: [],

    }
  },
  methods: {
    openDialog() {
      //this.$store.commit('changeActive', 4)
      sessionStorage.setItem('active',4)
    
      this.$router.push("/column");
    },
    /* 跳转文章详情 */
    detailFn(item) {
      //this.$store.commit('changeActive', 4)
      sessionStorage.setItem('active',4)
     
      this.$router.push({
        path: '/details',
        query: item
      })
    },
    //底部列表
    getContentInfoFn() {
      let that = this
      let params = {
        channelId: that.channelId,
        pageNo: "1",
        pageSize: "12",
        sort: 'topLevel',
      }
      getContentInfo(params).then(res => {
        if (res.status == 200) {
          that.footImgList = res.data.content.filter((item, index) => {
            return index < 4
          })
          that.footTextList = res.data.content.filter((item, index) => {
            return index >= 4
          })
        }
      })
    },
  }
}

</script>
<style scoped lang="less">
.main-width {
  height: 100%;
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

.bottom-btn {
  text-align: center;
  margin-top: 52px;
}

/* 底部文章列表 */
.artical-list {
  height: 612px;
  background: #F5F9FF;

  .artical-layout {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    padding-top: 68px;

    .left-box {
      
      display: flex;
      flex-wrap: wrap;
    }
  }

  .artical-right {
    padding-left: 40px;
    box-sizing: border-box;
  }

  .artical-img {
    position: relative;
    width: 288px;
    height: 184px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 6px;
    overflow: hidden;

    .artical-img-size {
      cursor: pointer;
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      position: relative;
      box-sizing: border-box;
      transition: all 0.3s;
      border-radius: 8px;
    }

    .artical-img-size:hover {
      transform: scale(1.1);
    }

    .artical-img-mask {
      color: #fff;
      text-align: center;
      font-size: 16px;
      line-height: 44px;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 44px;
      width: 100%;
      background: rgba(0, 0, 0, 0.60);
      border-radius: 8px;

      span {
        display: inline-block;
        width: 80%;
        height: 33px;
        overflow: hidden;
      }
    }
  }


}
</style>