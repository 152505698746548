<!-- 顶部表单 -->
<template>
  <div :class="formStyle ? 'form-box form' : 'form-inline form'">
    <template v-if="show == '1'">
      <div class="contactTitle">立即体验</div>
      <el-form :model="form" :rules="rules" ref="formRef" :inline="inline">
        <el-form-item prop="companyname">
          <el-autocomplete :trigger-on-focus="false" @focus="iscompany = 1" @blur="iscompany = 2" :debounce=debounce
            v-model="form.companyname" :fetch-suggestions="querySearchAsync" @select="handleSelect" placeholder="请输入单位名称">
            <template slot-scope="{item}">
              <div v-html="item.value">{{item.value}}</div>
            </template>
            <span slot="prefix">
              <div v-if="iscompany == 2" class="company"></div>
              <div v-else class="company-blue"></div>
            </span>
          </el-autocomplete>
        </el-form-item>
        <!-- <el-form-item prop="usename">
          <el-input @focus="isfocususename = 1" @blur="isfocususename = 2" placeholder="请输入姓名" v-model="form.usename">
            <span slot="prefix">
              <div v-if="isfocususename == 2" class="usename"></div>
              <div v-else class="usename-blue"></div>
            </span>
          </el-input>
        </el-form-item> -->
        <el-form-item prop="phone">
          <el-input @focus="isfocus = 1" @blur="isfocus = 2" placeholder="请输入手机号" v-model="form.phone">
            <span slot="prefix">
              <div v-if="isfocus == 2" class="phone"></div>
              <div v-else class="phone-blue"></div>
            </span>
          </el-input>
        </el-form-item>
        <el-form-item prop="checkCode" class="last">
          <el-input @focus="focuscode = 1" @blur="focuscode = 2" placeholder="请输入验证码" v-model="form.checkCode">
            <span slot="prefix">
              <div v-if="focuscode == 2" class="safenum"></div>
              <div v-else class="safenum-blue"></div>
            </span>
            <span v-if="showCode" slot="suffix" @click="sendCode">发送验证码</span>
            <span v-else slot="suffix">{{ timeNum }}s</span>
          </el-input>
        </el-form-item>
        <el-form-item class="action-btn">
          <el-button type="primary" class="start-btn" @click="sendFormFn">立即提交</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template v-else>
      <div class="success-box">
        <img src="@/assets/indestry/zu.png" alt="">
        <div class="text-1">提交成功</div>
        <div class="text-2">我们会在3个工作日内与您联系,请注意接听来电</div>
        <el-button class="btn" type="primary" @click="successFn">好的</el-button>
      </div>
    </template>

  </div>
</template>
<script>
import { getPhoneCode, saveForm, getCompanyName } from '@/utils/api'
export default {
  name: 'topform',
  components: {},
  created() {

  },
  props: {
    //行内属性
    inline: {
      default: false
    },
    title: {
      default: true
    },
    //行内样式盒子
    formStyle: {
      default: true
    }
  },
  data() {
    return {
      isfocus: 2,
      focuscode: 2,
      iscompany: 2,
      isfocususename: 2,
      form: {
        registerProduct: '金企盾双重预防管理系统',
        interestProduct: '金企盾双重预防管理系统',
        concreteDemand: '想要了解此产品',
        sourceway: '',
        companyname: '',
        phone: '',
        checkCode: '',
        usename:''
      },
      companyList: [],
      debounce: 500,
      rules: {
        companyname: [{ required: true, trigger: 'change' },],
        phone: [{ required: true, trigger: 'blur' },],
       // usename: [{ required: true, trigger: 'blur' },],
        checkCode: [{ required: true, trigger: 'blur' },],
        
      },
      timeNum: 60,
      showCode: true,
      timeOut: null,
      show: '1'
    }
  },
  methods: {
    //获取验证码
    sendCode() {
      let that = this
      let num = that.form.phone
      var pattern = /0?(13|14|15|17|18|19)[0-9]{9}/
      let result = pattern.test(num)
      if (!result) {
        that.$message.warning('无效手机号')
      } else {
        let data = {}
        data.phoneNumber = that.form.phone
        getPhoneCode(data).then(res => {
          that.timeNum = 60
          that.showCode = false
          clearInterval(that.timeOut)
          that.timeOut = setInterval(() => {
            if (that.timeNum == 1) {
              that.showCode = true
            }
            that.timeNum--
          }, 1000)
        })

      }

    },
    handleSelect(value) {
      this.form.companyname = value.name
    },
    /*  changeFn(){
         debugger
         if(this.companyList){
             let companyNameList = this.companyList.map(item => item.name)
             let flag = companyNameList.includes(this.form.companyname)
             if(!flag){
                 this.form.companyname = ''
                 //return this.$message.warning('请选择公司名称')
             }
         }
     }, */
    sendFormFn() {
      let that = this
      /* 测试注册成功后的展示效果 */
      that.$refs['formRef'].validate((valid) => {
        if (valid) {
          if (this.companyList) {
            let companyNameList = this.companyList.map(item => item.name)
            let flag = companyNameList.includes(this.form.companyname)
            if (!flag) {
              this.form.companyname = ''
              return this.$message.warning('请选择公司名称')
            } else {
              saveForm(that.form).then(res => {
                if (res.data == '注册成功') {
                  clearInterval(that.timeOut)
                  that.showCode = true
                  that.form = {}
                  that.$refs['formRef'].clearValidate()
                  if (!that.formStyle) {
                    that.$message.success('注册成功，稍后会有工作人员与您取得联系')
                  } else {
                    that.show = '2'
                  }
                } else {
                  that.$message.warning(res.data)
                }
              }).catch(err => {
                that.$message.error(err)
              })
            }
          }
        } else {
          return false
        }
      })
    },
    querySearchAsync(data, cb) {
      if (data) {
        getCompanyName({ word: data }).then(res => {
          if (res.status == 200) {
            if (res.data.result) {
              let arr = res.data.result.items
              let newHtml = `<span style="color:brown">${data}</span>`
              arr.forEach(item => {
                item.value = item.name.replace(data, newHtml)
              })
              cb(arr)
              this.companyList = arr
            } else {
              console.log('没有数据');
            }
          } else {
            this.$message.warning(res.msg)
          }
        }).catch(err => {
          this.$message.error(err)
        })
      }
    },
    clearValidateFn() {
      this.form.companyname = ''
      this.form.phone = ''
      this.form.checkCode = ''
      this.$nextTick(() => {
        this.$refs.formRef.clearValidate()
      })
    },
    successFn() {
      this.$emit('closeform')
      this.form.companyname = ''
      this.form.phone = ''
      this.form.checkCode = ''
      this.show = '1'
    }
  }
}

</script>
<style scoped lang="less">
.form {
  .contactTitle {
    font-size: 18px;
    font-weight: 700;
    color: #2E2E2E;
    text-align: center;
    margin-bottom: 18px;
  }

  span {
    font-size: 14px;
    cursor: pointer;
    color: #2377FF;
  }

  .title {
    text-align: center;
    font-size: 20px;
    font-family: PINGFANG MEDIUM;
    font-weight: bold;
    color: #2E2E2E;
    line-height: 48px;
    margin-bottom: 15px;
  }

  .company {
    display: inline-block;
    background-image: url(@/assets/safeEncyclopedias/form1white.png);
    width: 16px;
    height: 16px;
    background-size: 100% 100%;
  }

  .company-blue {
    display: inline-block;
    background-image: url(@/assets/safeEncyclopedias/gongsiicon.png);
    width: 16px;
    height: 16px;
    background-size: 100% 100%;
  }

  .usename {
    display: inline-block;
    background-image: url(@/assets/index/job.png);
    width: 15px;
    height: 15px;
    background-size: 100% 100%;
  }

  .usename-blue {
    display: inline-block;
    background-image: url(@/assets/index/job_blue.png);
    width: 15px;
    height: 15px;
    background-size: 100% 100%;
  }

  .phone {
    display: inline-block;
    background-image: url(@/assets/index/phone.png);
    width: 12px;
    height: 17px;
    background-size: 100% 100%;
  }

  .phone-blue {
    display: inline-block;
    background-image: url(@/assets/safeEncyclopedias/phoneicon.png);
    width: 12px;
    height: 17px;
    background-size: 100% 100%;
  }

  .safenum {
    display: inline-block;
    background-image: url(@/assets/index/safenum.png);
    width: 14px;
    height: 16px;
    background-size: 100% 100%;
  }

  .safenum-blue {
    display: inline-block;
    background-image: url(@/assets/safeEncyclopedias/safeicon.png);
    width: 14px;
    height: 16px;
    background-size: 100% 100%;
  }
}

.form-box {

  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px 5px 5px 5px;
  opacity: 0.95;
  padding: 20px 15px 20px;
  box-sizing: border-box;

  .start-btn {
    margin-top: 10px;
    width: 100%;
    height: 48px;
    background: #2377FF;
    box-shadow: 0px 3px 6px 1px rgba(4, 61, 219, 0.34);
    border-radius: 5px 5px 5px 5px;

  }

  ::v-deep .el-input__prefix {
    top: 3px;
  }


}

.form-inline {
  width: 100%;
  margin-top: 70px;
  text-align: center;
  font-size: 16px;

  .start-btn {
    margin-left: 10px;
    width: 114px;
    height: 50px;
    background-image: linear-gradient(180deg, #FFC43C 0%, #F49D3D 100%);
    border-radius: 6px;
  }

  ::v-deep .el-input__inner {
    width: 352px;
    height: 50px;
  }

  .last {
    ::v-deep .el-input__inner {
      width: 272px;
    }
  }

  .action-btn {
    ::v-deep .el-input__inner:last-child {
      width: 100%;
    }
  }

  ::v-deep .el-input__prefix {
    top: 8px;
  }

  ::v-deep .el-input__suffix {
    top: 5px;
  }


}

.success-box {
  margin-top: 18px;
  text-align: center;

  img {
    width: 63px;
  }

  .text-1 {
    font-size: 18px;
    color: #202124;
    margin-top: 10px;
    margin-bottom: 20px;
    box-sizing: border-box;

  }

  .text-2 {
    font-style: 14px;
    color: #202124;
    margin-bottom: 20px;
    box-sizing: border-box;

  }

  .btn {
    background: linear-gradient(90deg, #018DFF 0%, #1A74F1 100%);
    border-radius: 5px 5px 5px 5px;
    padding: 9px 17px
  }
}

::v-deep .el-form-item__error {
  display: none;
}

.el-form-item {
  margin-bottom: 12px;
}

.el-autocomplete {
  width: 100%
}

::v-deep .el-input__suffix {
  right: 10px;
}

::v-deep .el-input__prefix {
  left: 10px;
}
</style>