<template>
  <div class="foot-wrapper">
    <!-- 开始体验 -->
    <div class="start-wrapper" v-if="$store.state.mobileActive !== 2">
      <div class="banner-title-start">{{ exprienceTitle }}</div>
      <div class="start-btn" @click="exprienceFn">开始体验</div>
    </div>
    <div class="start-wrapper-product" v-else>
      <div class="start-product-title">{{ productTitle }}</div>
      <div class="start-product-btn" @click="exprienceFn">开始体验</div>
    </div>
   
      <div class="foot-top">
        <div class="foot-tel">
          <span class="tel-icon"></span>
          <span>服务热线: 400-600-7966</span>
        </div>
        <div class="foot-address">
          <span class="address-icon"></span>
          <span>公司地址: 济南市高新区新泺大街1166号奥盛大厦2号楼</span>
        </div>
        
        <div class="copyright">
          <div>© 2016-2022 金现代信息产业股份有限公司</div>
          <div>鲁ICP备09033671</div>
          <div>鲁公网安备 37010102000748号</div>
        </div>
      </div>
      <div class="foot-bottom">
        <a id="call" class="tel-btn" @click="call(jxdPhone)">电话咨询 {{ jxdPhone }}</a>
        <div class="experience-btn" @click="exprienceFn">立即体验</div>
      </div>
      <van-popup round safe-area-inset-bottom v-model="show" position="bottom" :style="{ width: '100vw' }" @open="openFn">
        <mobileForm @closeform="closeFormFn" ref="mobileRef"></mobileForm>
      </van-popup>
    
  </div>
</template>
<script>
import mobileForm from '@/views/mobile/components/mobileForm.vue'
export default {
  name: '',
  components: { mobileForm },
  created() {

  },
  data() {
    return {
      show: false,
      exprienceTitle: '智能化管控，落实双重预防机制建设',
      productTitle: '金企盾双重预防管理系统',
      jxdPhone: '400-600-7966'
    }
  },
  methods: {
    exprienceFn() {
      this.show = true
    },
    closeFormFn() {

      this.show = false
    },
    openFn() {
      this.$refs.mobileRef.clearValidateFn()
    },
    // 拨号
    call(number) {
      let a = document.getElementById("call");
      // 给a标签的href属性赋值
      a.setAttribute("href", 'tel:' + number);
      a.click();
    },
  }
}

</script>
<style lang="less" scoped>
.foot-wrapper{
  height: 358px;
}
.start-wrapper {
  height: 141px;
  background-image: url(@/assets/mobile/experience2.png);
  background-size: 100%;
  padding-top: 30px;
  box-sizing: border-box;
  text-align: center;

  .banner-title-start {
    font-size: 16px;
    color: #fff;
    margin-bottom: 20px;
    font-weight: 700;
  }

  .start-btn {
    display: inline-block;
    width: 100px;
    height: 36px;
    line-height: 36px;
    background: #FFFFFF;
    color: #007AEC;
    border-radius: 3px;
    font-size: 14px;

  }
}

.start-wrapper-product {
  height: 141px;
  background-image: url(@/assets/mobile/product-exprience-bg.svg);
  background-size: 100%;
  padding-top: 30px;
  padding-left: 20px;
  box-sizing: border-box;
  text-align: left;

  .start-product-title {
    font-size: 16px;
    color: rgba(46, 46, 46, 1);
    margin-bottom: 20px;
    font-weight: 700;

  }

  .start-product-btn {
    display: inline-block;
    width: 100px;
    height: 36px;
    line-height: 36px;
    border-radius: 3px;
    text-align: center;
    background-color: rgba(13, 128, 255, 1);
    color: #fff;
    font-size: 14px;
  }
}




  .foot-top {
    background-color: #2e2e2e;
    height: 162px;
    padding: 24px 15px 13px 15px;
    font-size: 12px;
    box-sizing: border-box;

    .foot-tel,
    .foot-address {
      display: flex;
      align-items: center;
      color: #fff;
      margin-bottom: 10px;
      
    }
    .foot-address{
      padding-bottom: 20px;
      border-bottom: 1px solid hsla(0,0%,100%,.2)
    }

    .tel-icon {
      display: inline-block;
      background-image: url(@/assets/mobile/phone.png);
      background-size: contain;
      margin-right: 5px;
      width: 11px;
      height: 11px;
    }

    .address-icon {
      display: inline-block;
      background-image: url(@/assets/mobile/address.png);
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 5px;
      width: 11px;
      height: 11px;
    }

    .line {
      margin-top: 20px;
      margin-bottom: 10px;
      border: solid 1px rgba(255, 255, 255, 0.2)
    }

    .copyright {
      color: #67707D;
      font-size: 11px;
      

      div {
        margin-bottom: 5px
      }

      ;
    }
  }

  .foot-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    height: 56px;
    display: flex;
    align-items: center;

    .tel-btn {
      width: 60%;
      height: 100%;
      line-height: 56px;
      text-align: center;
      color: #186CF5;
      font-weight: bold;
      background-color: #DCE9FF;
    }

    .tel-btn:hover {
      color: #0a58ca;

    }

    .experience-btn {
      width: 40%;
      text-align: center;
      height: 100%;
      line-height: 56px;
      color: #fff;
      font-weight: bold;
      background: linear-gradient(271deg, #0191FF 0%, #106CEC 100%);
    }
  }

</style>