<template>
  <div class="customer-box">
    <div class="title">{{ title }}</div>
    <van-swipe :autoplay="3000" @change="onChange">
      <van-swipe-item>
        <div class="customer-wrapper">
          <div class="customer-item-box" v-for="item in imgList" :key="item.id">
            <img class="customer-item" :src="item.url" />
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="customer-wrapper">
          <div class="customer-item-box" v-for="item in secondImgList" :key="item.id">
            <img class="customer-item" :src="item.url" />
          </div>
        </div>
      </van-swipe-item>
      <template #indicator>
        <div :class="{ 'custom-indicator-pre': true, 'active': active === 0 }"></div>
        <div :class="{ 'custom-indicator-next': true, 'active': active === 1 }"></div>
      </template>
    </van-swipe>
  </div>
</template>
<script>

export default {
  name: '',
  components: {},
  created() {

  },
  data() {
    return {
      title: '典型客户',
      imgList: [
        { url: require('@/assets/mobile/product-img/sqjt.png'), id: 0 },
        { url: require('@/assets/mobile/product-img/jljt.png'), id: 1 },
        { url: require('@/assets/mobile/product-img/sx.png'), id: 2 },
        { url: require('@/assets/mobile/product-img/wh.png'), id: 3 },
        { url: require('@/assets/mobile/product-img/zgsh.png'), id: 4 },
        { url: require('@/assets/mobile/product-img/zgsy.png'), id: 5 }
      ],
      secondImgList: [
        { url: require('@/assets/mobile/product-img/jingbo.png'), id: 0 },
        { url: require('@/assets/mobile/product-img/keshun.png'), id: 1 },
        { url: require('@/assets/mobile/product-img/saiousi.png'), id: 2 },
        { url: require('@/assets/mobile/product-img/haiou.png'), id: 3 },
        { url: require('@/assets/mobile/product-img/tianjin.png'), id: 4 },
        { url: require('@/assets/mobile/product-img/hualu.png'), id: 5 }
      ],
      active: 0

    }
  },
  methods: {
    onChange(index) {
      this.active = index
    }
  }
}

</script>
<style scoped lang="less">
.customer-box {
  width: 100%;
  padding-top: 40px;
  margin-bottom: 30px;
  ::v-deep .van-swipe__track{
    margin-bottom: 15px;
  }

  .customer-wrapper {
    display: flex;
    justify-content: center;
    row-gap: 10px;
    column-gap: 10px;
    flex-wrap: wrap;
    width: 375px;
    margin: 0 auto;

    .customer-item-box {
      width: 165px;
      height: 57px;

      .customer-item {
        width: 100%;
        height: 100%;
      }
    }
  }

  .custom-indicator-pre {
    display: inline-block;
    position: absolute;
    right: 43%;
    bottom: 0px;
    width: 15px;
    height: 3px;
    font-size: 12px;
    background:rgba(232, 238, 247, 1);

    
  }
  .custom-indicator-next {
    display: inline-block;
    position: absolute;
    right: 50%;
    bottom: 0px;
    width: 15px;
    height: 3px;
    font-size: 12px;
    background:rgba(232, 238, 247, 1);
   
  }

  .active {
    background: rgba(13, 142, 255, 1);
  }

}

.title {
  font-size: 26px;
  font-weight: bold;
  color: #2E2E2E;
  width: fit-content;
  margin: auto;
  margin-bottom: 20px;
}
</style>