<template>
   <el-dialog width="590px" :destroy-on-close="true" lock-scroll center :visible.sync="visible" @close="closeFn">
    <div class="iframe-box">
      <iframe id="850396b0635f43e880ce00762e6cd1ec" width="100%" height="100%" frameborder="0"
            src="https://app.swhudong.com/apply/850396b0635f43e880ce00762e6cd1ec" style="display: none;"
            onload="this.style.display='block'" ></iframe>
    </div>
      
  </el-dialog>
    
</template>
<script>
export default {
    name: 'frameComponents',
    components: {},
    created() {

    },
    props: {
        dialogVisible:{}
    },
    watch: {
        dialogVisible: function (nelVal, oldVal) {
            this.visible = nelVal
        }
    },
    data() {
        return {
          visible:false
        }
    },
    methods: {
      closeFn(){
        this.$emit('update:dialogVisible',false)
      }
    }
}

</script>
<style scoped lang="less">
.iframe-box{
 
  height: 488px;
  
}
::v-deep .el-dialog__header {
    padding: 0;
}

::v-deep .el-dialog__body {
    padding: 0 !important
}

::v-deep .el-dialog {
  background: transparent;
  box-shadow: 0 0 0;
}
</style>