<template>
  <div>
    <van-popup closeable v-model="$store.state.popStatus" position="right" :style="{ height: '100vh', width: '70vw' }">
      <div class="mobile-logo">
        <img src="@/assets/index/jxdgreen.png" alt="">
      </div>
      <div class="pop-box">
        <div :class="{ 'pop-item': true, 'active': $store.state.active == 1 }" @click="popBtn(1)">
          首页
        </div>
        <div @click="popBtn(2)" :class="{ 'pop-item': true, 'active': $store.state.active == 2 }">
          产品功能
        </div>
        <div @click="popBtn(4)" :class="{ 'pop-item': true, 'active': $store.state.active == 4 }">
          安全百科
        </div>
        <div @click="popBtn(5)" :class="{ 'pop-item': true, }">
          关于我们
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>

export default {
  name: '',
  components: {},
  created() {
    //页面刷新时 session里边也有备份 导航状态有保存
    var temp = sessionStorage.getItem('active')
    if (!temp) {
      sessionStorage.setItem('active', 1)
    } else {
      this.$store.commit('changeActive', temp)
    }
  },
  watch: {
    $route: {
      handler(to) {
        switch (to.name) {
          case 'app':
            sessionStorage.setItem('active', 1)
            this.$store.commit('changeActive', 1)
            break;
          case 'product':
            sessionStorage.setItem('active', 2)
            this.$store.commit('changeActive', 2)
            break;
          case 'column':
            sessionStorage.setItem('active', 4)
            this.$store.commit('changeActive', 4)
            break;
          case 'details':
            sessionStorage.setItem('active', 4)
            this.$store.commit('changeActive', 4)
            break;
          default:
            break;
        }
      },
      immediate: true
    },
  },
  data() {
    return {
      show: false,
      isActive: false,
    }
  },
  methods: {
    popBtn(val) {
      switch (val) {
        case 1:
          this.$router.push('/')
          sessionStorage.setItem('active', 1)
          break;
        case 2:
          this.$router.push('/product')
          sessionStorage.setItem('active', 2)
          break;
        case 4:
          this.$router.push('/column')
          sessionStorage.setItem('active', 4)
          break;
        case 5:
          window.open('http://www.jxdinfo.com/overview/')
          break;
        default:
          break;
      }
      this.$store.commit('CHANGE_POP_STATUS',false)
    },
  }
}

</script>
<style scoped lang="less">
.mobile-logo{
  padding: 20px;
  img{
    width: 88px;
    height: 29px;
  }
}

.pop-item {
  padding: 0 20px;
  height: 56px;
  line-height: 56px;
  box-sizing: border-box;
  color: #2e2e2e;
  border-bottom: 1px solid hsla(0, 0%, 82%, .3);
  font-size: 15px;
  cursor: pointer;
}

.active {
  background-color: #ecf5ff;
  color: #186cf5
}

.pop-item:hover {
  background-color: #ecf5ff;
}
</style>
