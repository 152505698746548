<template>
  <div>
    <div class="pc">
      <pageHeaderPC></pageHeaderPC>
      <homeView></homeView>
      <!-- form弹框 -->
      <!-- <formDialog :frameVisible.sync="frameVisible"></formDialog> -->
    </div>
    <div class="mobile">
      <mobile-home>
      </mobile-home>
    </div>
  </div>
</template>
<script>
/* import formDialog from '@/components/dialogForm.vue' */
import pageHeaderPC from '../components/navigationPC.vue'
import homeView from './HomeView.vue'
import mobileHome from '../views/mobile/mobileHome.vue'
export default {
  name: "",
  components: {  mobileHome,homeView ,pageHeaderPC},
  data() {
    return {
    };
  },
  watch: {
    
  },
  created() {
  },
  methods: {

   
  }
}

</script>
<style scoped lang="less">
@import url('./mobile/css/mobileCss.css');
</style>