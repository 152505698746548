<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  name: "app",
  data() {
    return {
    
    };
  },
  
  created() {
  },
  methods: {

  }
};
</script>
<style lang="less" scoped>
#app {
  font-family: PINGFANG MEDIUM;
}


</style>
