<template>
  <div class="nav-box">
    <div class="nav-left">
      <div class="nav-logo">
        <img src="@/assets/index/jxdgreen.png" alt="">
      </div>
      <div class="stock-box">
        <div class="stock-name">股票名称: 金现代</div>
        <div class="stock-code">股票代码: 300830</div>
      </div>
    </div>
    <div class="nav-right">
      <img @click="showPopup" src="@/assets/mobile/list.png" alt="">
    </div>
  </div>
  
</template>
<script>
export default {
  name: 'pageHeader',
  components: {},
  created() {
 
  },
  data() {
    return {
    
    }
  },
  methods: {
    showPopup(){
      this.$store.commit('CHANGE_POP_STATUS',true)
    }
  }
}

</script>
<style scoped lang="less">
.nav-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  height: 54px;
  z-index: 999;
  background-color: #fff;

  .nav-left {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 11px;

    .nav-logo {
      width: 92px;
      height: 30px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .nav-right{
    width: 17px;
    height: 17px;
    img{
      width: 100%;
      height: 100%;
      vertical-align: baseline;
    }
  }

}</style>