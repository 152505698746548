import { render, staticRenderFns } from "./ProductFeature.vue?vue&type=template&id=27552d43&scoped=true&"
import script from "./ProductFeature.vue?vue&type=script&lang=js&"
export * from "./ProductFeature.vue?vue&type=script&lang=js&"
import style0 from "./ProductFeature.vue?vue&type=style&index=0&id=27552d43&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27552d43",
  null
  
)

export default component.exports