<template>
    <div>
        <page-header></page-header>
        <home-component></home-component>
        <page-foot></page-foot>
        <pop></pop>
    </div>
</template>
<script>
import pageHeader from '@/components/pageHeader.vue';
import pageFoot from '@/components/pageFoot.vue';
import pop from './pop.vue'
import homeComponent from './components/homeComponent'
export default {
    name: '',
    components: { pageHeader, pageFoot, pop, homeComponent },
    created() {

    },
    data() {
        return {

        }
    },
    methods: {

    }
}

</script>
<style scoped></style>