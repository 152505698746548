//获取热门推荐
import axios from "axios";
let BSAE_RUL = "";
if (process.env.NODE_ENV == "development") {
  BSAE_RUL = "/api";
} else if (process.env.NODE_ENV == "production") {
  BSAE_RUL = "http://jqd.jxdinfo.com/api";
}
/* if (process.env.NODE_ENV == "development") {
  BSAE_RUL = "/api";
} else if (process.env.NODE_ENV == "production") {
  BSAE_RUL = "http://192.168.2.186:8800/api";
} */
axios.defaults.baseURL = BSAE_RUL;
export function getContentInfo(params) {
  return new Promise((resolve, reject) => {
    axios
      .postForm("/contentInfo_channelId.jspx", params)
      .then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function topLevelArticleList(params) {
  return new Promise((resolve, reject) => {
    axios
      .postForm("/contentInfo_channelId.jspx", params)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.content.length === 0) {
            return topLevelArticleList(params);
          }
          resolve(res.data.content);
        } else {
          console.log(res.msg);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
//获取热门标签
export function getTipList(params) {
  return new Promise((resolve, reject) => {
    axios
      .postForm("/contentTagByCount.jspx", params)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.content.length === 0) {
            return getTipList(params);
          }
          resolve(res.data.content);
        } else {
          reject(res.msg);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
//获取验证码
export function getPhoneCode(params) {
  return new Promise((resolve, reject) => {
    axios
      .postForm("/sendPhoneCode.jspx", params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function saveForm(params) {
  return new Promise((resolve, reject) => {
    axios
      .postForm("/saveForm.jspx", params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
/* 查询企业名称 */
export function getCompanyName(params) {
  return new Promise((resolve, reject) => {
    axios
      .postForm("/queryCompanyName.jspx", params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
/* 获取文章详情 */

