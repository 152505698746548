<template>
    <div class="jxd-foot">
        <div class="main-width">
            <div @click="jxdLogo">
                <img class="jxd-logo" src="@/assets/indestry/logo_white.b8268f38.png" alt="">
            </div>
            <div @click="titleClick" v-for="(item, index) in footList" :key="index" class="foot-title">
                <div class="title" v-for="(i, index) in item" :key="index">{{ i }}</div>
            </div>
            <div class="rightqr">
                <img class="qrcode" src="@/assets/indestry/twoma.png" alt="">
                <div style="text-align:center">关注公众号</div>
            </div>

        </div>
        <div class="jxd-bottom">
            <div>
                <span>© 2016-{{year}}</span>
                <span>金现代信息产业股份有限公司</span>
                <span>鲁ICP备09033671</span>
                <img src="@/assets/indestry/recordicon.ef881569.png" alt="">
                <span>鲁公网安备 37010102000748号</span>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    name: '',
    components: {},
    created() {

    },
    data() {
        return {
            footList: [
                ['首页'],
                ['产品功能'],
                ['安全百科'],
                ['关于我们'],
                ['联系我们', '服务热线：', '400-600-7966', '公司地址：', '济南市高新区奥盛大厦2号楼']
            ],
            year:new Date().getFullYear()
        }
    },
    methods: {
        jxdLogo() {
            /* window.location.href = 'http://www.jxdinfo.com/' */
            window.open('http://www.jxdinfo.com/')
        },
        titleClick(e) {
            let that = this
            switch (e.target.outerText) {
                case '首页':
                    //that.$store.commit('changeActive', 1)
                    sessionStorage.setItem('active',1)
                    document.dispatchEvent(that.$active)
                    that.$router.push("/");
                    break;
                case '产品功能':
                    //that.$store.commit('changeActive', 2)
                    sessionStorage.setItem('active',2)
                    document.dispatchEvent(that.$active)
                    that.$router.push('/product')
                    break;
                case '行业政策':
                    //that.$store.commit('changeActive', 3)
                    sessionStorage.setItem('active',3)
                    document.dispatchEvent(that.$active)
                    that.$router.push('/policy')
                    break;
                case '安全百科':
                    //that.$store.commit('changeActive', 4)
                    sessionStorage.setItem('active',4)
                    document.dispatchEvent(that.$active)
                    that.$router.push('/column')
                    break;
                case '关于我们':
                    window.open('http://www.jxdinfo.com/overview/')
                    break;
                default:
                    break;
            }
            console.log(e.target.outerText);
            window.scroll({
                top: 0,
                left: 0
            })
        }
    }
}

</script>
<style scoped lang="less">
.jxd-foot {
    background: #2E2E2E;
    padding-top: 45px;
    position: relative;

    .main-width {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .foot-title div:first-child {
            color: #fff;
            font-size: 16px;
        }

        .foot-title {
            cursor: pointer;

            div {
                color: #BDBDBD;
                font-size: 14px;
                margin-bottom: 12px;
            }
        }

        .rightqr {
            div {
                font-size: 14px;
                color: #BDBDBD;
                margin-top: 5px;
            }
        }



    }

    .jxd-bottom {
        padding: 7px 0 11px 0;
        box-sizing: border-box;
        width: 100%;
        margin-top: 20px;
        color: #67707d;
        text-align: center;
        background-color: #2E2E2E;
        border-top: 1px solid rgba(255, 255, 255, .1);
        font-size: 14px;


        span {
            margin-right: 25px;
        }
        img{
            margin-right: 5px;
        }
    }

    .jxd-logo {
        width: 119px;
        height: 41px;
        cursor: pointer;
    }

    .qrcode {
        width: 93px;
    }
}
</style>