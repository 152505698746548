<template>
  <div class="home-box">
    <!-- 双重预防管理系统 -->
    <div class="home-banner-wrapper">
      <div class="banner-title-home">{{ bannerTitle }}</div>
      <div class="banner-disc">
        <div v-for="item in discList" :key="item.id" class="disc-item">
          <img :src="item.imgSrc" />
          <span>{{ item.txt }}</span>
        </div>
      </div>
    </div>
    <!-- 八大功能模块 -->
    <div class="eight-feature-wrapper">
      <div class="banner-title eight-title">{{ eightBannerTitle }}</div>
      <img class="eight-banner" src="@/assets/mobile/eight-feature-2.png">
    </div>
    <!-- 核心价值 -->
    <div class="core-value-wrapper">
      <div class="core-title">{{ coreTitle }}</div>
      <div class="card-box">
        <div class="pre-card">
          <div class="card-title-box pre-title-box">
            <div class="card-title-btn card-title-btn-pre">使用前</div>
            <div class="core-card-bg-pre core-card-bg"></div>
          </div>
          <div class="card-item pre-bg" v-for="item in preItem" :key="item.id">
            <img :src="item.icon" class="error-icon" />
            <span>{{ item.txt }}</span>
          </div>
        </div>
        <div class="next-card">
          <div class="card-title-box next-title-box">
            <div class="card-title-btn card-title-btn-next">使用后</div>
            <div class="core-card-bg-next core-card-bg"></div>
          </div>
          <div class="card-item next-bg" v-for="item in nextItem" :key="item.id">
            <img :src="item.icon" class="error-icon" />
            <span>{{ item.txt }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 行业应用背景 -->
    <div class="industry-wrapper">
      <div class="banner-title">{{ industryTitle }}</div>
      <div class="industry-banner">
        <div class="industry-item" v-for="item in industryList" :key="item.id">
          <img :src="item.imgUrl" alt="">
          <div class="industry-mask"></div>
          <div class="industry-txt">{{ item.txt }}</div>
        </div>
      </div>
    </div>
    <!-- 典型客户 -->
    <div class="customer-wrapper">
      <div class="banner-title-customer">{{ customerTitle }}</div>
      <div class="customer-wrapper-box">
        <div class="customer-box">
          <div class="cus-item" v-for="item in customerList" :key="item.id">
            <img :src="item.imgUrl" alt="">
          </div>
        </div>
      </div>

    </div>
    <!-- 品牌實力 -->
    <div class="brand-wrapper">
      <div class="banner-title">{{ brandTitle }}</div>
      <swiper :options="thireSwiperOption" class="my-swipe">
        <swiper-slide v-for="(item) in brandList" :key="item.id" class="swipe-item">
          <img :src="item.bgurl" alt="" class="swipe-bg">
          <div class="img-mask"></div>
          <img class="img-icon" :src="item.url" />
          <div class="swipe-title">{{ item.text1 }}</div>
          <div class="swipe-txt">{{ item.text2 }}</div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <!-- 文章列表 -->
    <div class="article-wrapper">
      <articleList :pageSize="5" :isShowPagination="false"></articleList>
    </div>
  </div>
</template>
<script>
import articleList from './articleListComponent.vue'
import "swiper/swiper.css";
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  name: '',
  components: {
    swiper,
    swiperSlide,
    articleList
  },
  created() {

  },
  data() {
    return {
      bannerTitle: '金企盾双重预防管理系统',
      eightBannerTitle: '八大功能模块',
      industryTitle: '行业应用背景',
      coreTitle: '核心价值',
      customerTitle: '典型客户',
      brandTitle: '企业品牌实力',
      discList: [
        { imgSrc: require('@/assets/index/duigou.png'), txt: '落实双重预防机制建设', id: 1 },
        { imgSrc: require('@/assets/index/duigou.png'), txt: '实现分层管控风险', id: 2 },
        { imgSrc: require('@/assets/index/duigou.png'), txt: '高效消除隐患', id: 3 },
        { imgSrc: require('@/assets/index/duigou.png'), txt: '高效消除隐患', id: 4 },
      ],
      preItem: [
        { icon: require('@/assets/mobile/error-icon.svg'), txt: '事后管理，亡羊补牢', id: 0 },
        { icon: require('@/assets/mobile/error-icon.svg'), txt: '标准不明，责任不清', id: 1 },
        { icon: require('@/assets/mobile/error-icon.svg'), txt: '排查治理，流程混乱', id: 2 },
        { icon: require('@/assets/mobile/error-icon.svg'), txt: '双防机制，推进不易', id: 3 }
      ],
      nextItem: [
        { icon: require('@/assets/mobile/right-icon.svg'), txt: '提前预防，事事安心', id: 0 },
        { icon: require('@/assets/mobile/right-icon.svg'), txt: '责任明确，权责清晰', id: 1 },
        { icon: require('@/assets/mobile/right-icon.svg'), txt: '隐患排查，闭环管理', id: 2 },
        { icon: require('@/assets/mobile/right-icon.svg'), txt: '奖励机制，全员参与', id: 3 },

      ],
      industryList: [
        { imgUrl: require('@/assets/index/huagong.jpg'), maskColor: '-89deg, rgba(22, 139, 160, 0.06) 0%, rgba(22, 139, 160, 0.50) 100%', txt: '化工企业' },
        { imgUrl: require('@/assets/index/yejin.jpg'), maskColor: '', txt: '冶金企业' },
        { imgUrl: require('@/assets/index/hospital.jpg'), maskColor: '', txt: '医院' },
        { imgUrl: require('@/assets/index/jianshe.jpg'), maskColor: '', txt: '建设工地' },
        { imgUrl: require('@/assets/index/jiancai.jpg'), maskColor: '', txt: '建材制造企业' },
        { imgUrl: require('@/assets/index/jixie.jpg'), maskColor: '', txt: '机械制造企业' },
        { imgUrl: require('@/assets/index/feimei.jpg'), maskColor: '', txt: '非煤矿山厂' },
        { imgUrl: require('@/assets/index/fangzhi.jpg'), maskColor: '', txt: '纺织企业' },
        { imgUrl: require('@/assets/index/smoke.jpg'), maskColor: '', txt: '烟草企业' },
        { imgUrl: require('@/assets/index/gangtie.jpg'), maskColor: '', txt: '钢铁厂' },
      ],
      customerList: [
        { imgUrl: require('@/assets/mobile/cus-img/znshihua2.png'), id: 0 },
        { imgUrl: require('@/assets/mobile/cus-img/znshiyou2.png'), id: 1 },
        { imgUrl: require('@/assets/mobile/cus-img/jinling2.png'), id: 2 },
        { imgUrl: require('@/assets/mobile/cus-img/wanhao2.png'), id: 3 },
        { imgUrl: require('@/assets/mobile/cus-img/shengxing2.png'), id: 4 },
        { imgUrl: require('@/assets/mobile/cus-img/shengquan2.png'), id: 5 },
        { imgUrl: require('@/assets/mobile/cus-img/keshun2.png'), id: 6 },
        { imgUrl: require('@/assets/mobile/cus-img/saiousi2.png'), id: 7 },
        { imgUrl: require('@/assets/mobile/cus-img/jingbo2.png'), id: 8 },
        { imgUrl: require('@/assets/mobile/cus-img/haiou2.png'), id: 9 },
        { imgUrl: require('@/assets/mobile/cus-img/nuoliang2.png'), id: 10 },
        { imgUrl: require('@/assets/mobile/cus-img/hengsheng2.png'), id: 11 },
      ],
      brandList: [
        {
          url: require('@/assets/index/rjkf.png'), bgurl: require('@/assets/index/sl1.jpg'),
          text1: '20年软件开发经验',
          text2: '打造双重预防、作业票全流程管理等多款功能齐全、规范标准的产品', id: '1'
        },
        {
          url: require('@/assets/index/升级迭代.png'), bgurl: require('@/assets/index/sl2.jpg'), text1: '支持升级迭代', text2: '各模块独立设计，可拓展性强，支持升级迭代', id: '2'
        },
        {
          url: require('@/assets/index/国产化软硬件.png'), bgurl: require('@/assets/index/sl3.jpg'), text1: '支持国产化软硬件生态', text2: '与国内服务器、操作系统、中间件等厂家达成合作，支持国产软硬件', id: '3'
        },
        {
          url: require('@/assets/index/低代码开发平台.png'), bgurl: require('@/assets/index/sl4.jpg'), text1: '低代码开发平台', text2: ' 采用轻骑兵低代码开发平台，极大缩短项目交付周期，降低开发成本', id: '4'
        },
      ],
      thireSwiperOption: {
        pagination: {
          el: '.swiper-pagination', //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
        },
        //自动播放
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        loopedSlides: 4, //比你页面显示的数量大于2就好
        updateOnWindowResize: false,  //分辨率改变时，防止样式错乱加上！
        //循环
        loop: true,
        slidesPerView: "auto",  //显示个数
        spaceBetween: 18,  //每个模块的间距
        centeredSlides: true
      },

    }
  },
  methods: {

  }
}

</script>
<style scoped lang="less">
@import url(../css/mobileCss.css);

/* 管理系统 */
.home-banner-wrapper {
  height: 350px;
  position: relative;
  background-repeat: no-repeat;
  background-image: url(@/assets/mobile/homebanner.svg);
  //background-position: center;
  background-size: 100%;
  margin-top: 56px;
  color: #fff;
  padding-top: var(--banner-pad);
  box-sizing: border-box;

  .banner-title-home {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 700;

  }

  .banner-disc {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px;
    row-gap: 20px;
    column-gap: 10px;
    font-size: 15px;
    justify-content: center;
  }

  .banner-disc .disc-item:nth-child(2n - 1) {
    width: 174px;
  }

  .banner-disc .disc-item:nth-child(2n) {
    width: 150px;
  }

  .banner-disc .disc-item img {
    width: 13px;
    height: 13px;
    margin-right: 10px;
  }

}

/* 八大功能木块 */
.eight-banner {
  width: 100%;
  height: auto;
  /* position: relative;
  background-image: url(@/assets/mobile/eight-banner.svg);
  height: 330px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: 23px; */

}

.eight-title {}

/* 核心价值 */
.core-value-wrapper {
  background-color: rgba(245, 249, 255, 1);
  padding: 35px 0 40px;

  .core-title {
    font-size: 1.5rem;
    text-align: center;
    //padding: 43px 0 20px 0;
    margin-bottom: 30px;
    font-weight: 700;
  }

  .card-title-btn {
    width: 6.125rem;
    height: 1.5625rem;
    border-radius: 30px;
    border: 1px solid #9CC0ED;
    margin: 0 auto 4px;
    text-align: center;

  }

  .card-title-btn-pre {
    background: linear-gradient(98deg, #EFF6FF 0%, #C5DDFF 100%);

  }

  .card-title-btn-next {
    background: linear-gradient(90deg, #16A8FF 0%, #1C52E5 100%);
    position: relative;
    bottom: 5px;
  }

  .core-card-bg {
    height: 10px;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .core-card-bg-pre {
    background-image: url(@/assets/mobile/react.svg);
  }

  .core-card-bg-next {
    background-image: url(@/assets/mobile/react-blue.svg);
  }

  .card-box {
    //display: flex;
    padding: 0 20px;
    height: 230px;

    .card-title-box {
      padding-top: 15px;
      border-radius: 5px 5px 0 0;
    }

    .pre-title-box {

      background-color: #CBD9ED;

    }

    .next-title-box {
      padding-top: 23px;
      background: linear-gradient(90deg, #16A8FF 0%, #1C52E5 100%);
    }

    .pre-card {
      float: left;
      width: 47%;
      height: 230px;
      border-radius: 5px 0px 5px 5px;
    }

    .next-card {

      color: #fff;
      float: right;
      width: 53%;
      position: relative;
      bottom: 8px;
    }

    .card-item:last-child {
      border-radius: 0 0 5px 5px;
    }

    .card-item {
      display: flex;
      align-items: center;
      height: 46px;
      padding: 15px 10px;
      box-sizing: border-box;
      font-size: 13px;
      text-align: center;


      .error-icon {
        width: 13px;
        height: 13px;
        margin-right: 5px;
      }
      
    }


    .pre-bg:nth-child(2n) {
      background: #DDE5F0;
    }

    .pre-bg:nth-child(2n -1) {
      background: #E7EEF7;
    }

    .next-bg:nth-child(2n) {
      background: #1A69EC;
    }

    .next-bg:nth-child(2n - 1) {
      background: #1984F4;
    }
  }
}

/* 行业应用背景 */
.industry-wrapper {
  padding: 0 20px;
  margin-bottom: 30px;

  .industry-banner {
    overflow: scroll;
    display: grid;
    grid-template-columns: repeat(5, 107px);
    grid-template-rows: repeat(2, 87px);
    grid-gap: 5px;
    margin-bottom: 15px;
    border-radius: 5px;
    &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
    -webkit-overflow-scrolling: touch;
    -overflow-scrolling: touch;
}

    .industry-item {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      position: relative;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 7;
        border-radius: 5px;


      }

      .industry-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 8;
        border-radius: 5px;
      }

      .industry-txt {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
        color: #fff;
        font-size: 14px;
        width: 100%;
        text-align: center;
        font-weight: 800;
      }

    }

    .industry-item:nth-child(1) {
      .industry-mask {
        background-image: linear-gradient(270deg, rgba(64, 140, 246, 0.00) 0%, #408CF6 99%);
      }
    }

    .industry-item:nth-child(2) {
      .industry-mask {
        background-image: linear-gradient(270deg, rgba(103, 70, 0, 0.06) 0%, #B08323 99%);
      }
    }

    .industry-item:nth-child(3) {
      .industry-mask {
        background-image: linear-gradient(270deg, rgba(7, 101, 205, 0.06) 0%, #0765CD 99%);
      }
    }

    .industry-item:nth-child(4) {
      .industry-mask {
        background-image: linear-gradient(-89deg, rgba(0, 128, 182, 0.06) 0%, #0094D3 100%);
      }
    }

    .industry-item:nth-child(5) {
      .industry-mask {
        background-image: linear-gradient(270deg, rgba(0, 99, 199, 0.06) 0%, #0063C7 99%);
      }
    }

    .industry-item:nth-child(6) {
      .industry-mask {
        background-image: linear-gradient(270deg, rgba(51, 77, 156, 0.00) 0%, #334D9C 99%);
      }
    }

    .industry-item:nth-child(7) {
      .industry-mask {
        background-image: linear-gradient(270deg, rgba(68, 158, 175, 0.07) 0%, #449EAF 100%);
      }
    }

    .industry-item:nth-child(8) {
      .industry-mask {
        background-image: linear-gradient(270deg, rgba(20, 91, 184, 0.06) 0%, #145BB8 99%);
      }
    }

    .industry-item:nth-child(9) {
      .industry-mask {
        background-image: linear-gradient(270deg, rgba(160, 98, 0, 0.06) 0%, #A06200 99%);
      }
    }

    .industry-item:nth-child(10) {
      .industry-mask {
        background-image: linear-gradient(-89deg, rgba(22, 139, 160, 0.06) 0%, rgba(22, 139, 160, 0.50) 100%);
      }
    }
  }
}

/* 典型客户 */
.customer-wrapper {
  
  background-image: url(@/assets/mobile/customer-banner2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  height: 390px;
  padding-bottom: 20px;
  box-sizing: border-box;
  

  .banner-title-customer {
  
    font-size: 1.5rem;
    text-align: center;
    padding: 43px 0 20px 0;
    font-weight: 700;
  }

  .customer-wrapper-box {
  
    display: flex;
    justify-content: center;
    align-items: center;

    .customer-box {
      display: flex;
      width: 375px;
      flex-wrap: wrap;

      .cus-item {
        width: 25%;
        text-align: center;

        img {
          width: 82px;
          height: 88px;
        }
      }
    }
  }

}

/* 品牌实力 */
.brand-wrapper {
  height: 290px;
  width: 100vw;
  overflow: hidden;
  margin-bottom: 40px;

  .my-swipe {
    color: #fff;
    height: calc(100% - 90px);



    .swipe-item {
      width: 280px;
      //border-radius: 6px;

    }

    .swipe-bg {
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }

    .img-mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 7;
      background: rgba(0, 9, 24, 0.62);
      border-radius: 6px;


    }

    .img-icon {
      position: absolute;
      top: 20%;
      right: 43%;
      z-index: 8;
    }

    .swipe-title {
      position: absolute;
      top: 48%;
      width: 100%;
      text-align: center;

      z-index: 8;
      font-size: 16px;
      box-sizing: border-box;
    }

    .swipe-txt {
      position: absolute;
      top: 61%;
      width: 100%;
      text-align: center;
      padding: 0 10px;
      z-index: 8;
      font-size: 13px;
      box-sizing: border-box;

    }
  }
}

/* 文章列表 */
.article-wrapper {
  padding: 6px 15px 36px;
  background-color: rgba(245, 249, 255, 1);

}
</style>
<style scoped>
.text_37 {
  height: 32px;
  overflow-wrap: break-word;
  color: rgba(46, 46, 46, 1);
  font-size: 26px;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 48px;
}

.button_1 {
  background-color: rgba(13, 128, 255, 1);
  border-radius: 5px;
  margin: 20px 0 0 0;
  width: 170px;
  text-align: center;
}

.text_38 {
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 26px;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  line-height: 55px;
  text-align: left;
  white-space: nowrap;
}
</style>