import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/home.vue";
import policy from "../views/IndustryPolicy.vue";
import ProductFeature from "../views/ProductFeature.vue";
import store from "@/store";
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "app",
    component: home,
    meta: {
      metaInfo: {
        title:
          "金企盾双重预防管理系统_双重预防体系管理系统_双控系统|金企盾双防系统",
        keywords:
          "双重预防管理系统，双重预防管理机制，双防机制，双防系统，双控系统，风险分级，隐患排查",
        description:
          "金企盾双重预防机制管理系统落实双重预防机制建设，实现分层管控风险，高效消除隐患，遏制事故发生。",
      },
    },
  },
  {
    path: "policy",
    name: "policy",
    component: policy,
  },
  {
    path: "/product",
    name: "product",
    component: ProductFeature,
    meta: {
      metaInfo: {
        title:
          "双重预防管理系统_安全生产风险管控平台_双重预防机制管理系统|金企盾",
        keyWords:
          "双重预防系统，双重预防机制，安全生产管控平台，风险分级管控，隐患排查系统，双控系统，双防系统",
        description:
          "金企盾双重预防管理系统通过构建风险分级管控与隐患排查治理体系，形成预先辨识风险，周期排查隐患，及时发现与治理的管理方式，遏制事故发生，守护企业生命财产安全。",
      },
    },
  },
  {
    path: "/column/:id?",
    name: "column",
    component: () => import("../views/SafeEncyclopedias.vue"),
    meta: {
      metaInfo: {
        title:
          "双重预防机制建设_双重预防app_双重预防安全管理平台|金企盾双重预防管理系统",
        keyWords:
          "构建双重预防机制，双重预防机制实施方案，煤矿双重预防，危化品双重预防，双重预防安全管理平台",
        description:
          "金企盾双重预防管理系统，助力企业构建双重预防机制，实现企业安全风险自辨自控、隐患自查自治，为企业安全保驾护航，是企业安全管理的好伙伴！",
      },
    },
  },
  {
    path: "/details/:id",
    name: "details",
    component: () => import("../views/ArticleDetails.vue"),
    metaInfo: {
      title:
        "金企盾双重预防管理系统_双重预防体系管理系统_双控系统|金企盾双防系统",
      keywords:
        "双重预防管理系统，双重预防管理机制，双防机制，双防系统，双控系统，风险分级，隐患排查",
      description:
        "金企盾双重预防机制管理系统落实双重预防机制建设，实现分层管控风险，高效消除隐患，遏制事故发生。",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    return { x: 0, y: 0 };
  },
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.metaInfo) {
    store.commit("CHANGE_META_INFO", to.meta.metaInfo);
  }
  next();
});

export default router;
