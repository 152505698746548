<template>
  <div>
    <div :class="scroll == '1' ? 'fix pre-color' : 'fix after-color'">
      <div class="jxd-navbar">
        <!-- logo部分 -->
        <div>
          <div class="nav-logo">
            <a href>
              <img v-if="scroll == '1'" src="@/assets/index/jxdbai.png" alt />
              <img v-else src="@/assets/index/jxdgreen.png" alt />
            </a>
          </div>
          <div class="stock-box">
            <span class="stock-name">股票名称: 金现代</span>
            <span class="stock-code">股票代码: 300830</span>
          </div>
        </div>
        <!-- 导航部分 -->
        <div class="nav-box ">
          <ul class="nav-menu" ref="navRef" @click="handleClickNav">
            <li :class="$store.state.active == '1' ? 'active' : ''">
              <a data-id="1">首页</a>
              <span class="line"></span>
            </li>
            <li :class="$store.state.active == '2' ? 'active' : ''">
              <a data-id="2">产品功能</a>
              <span class="line"></span>
            </li>
            <!-- <li :class="$store.state.active == '3' ? 'active' : ''">
              <a data-id="3">行业政策</a>
              <span class="line"></span>

            </li> -->
            <li :class="$store.state.active == '4' ? 'active' : ''">
              <a data-id="4">安全百科</a>
              <span class="line"></span>
            </li>
            <li>
              <a data-id="5">关于我们</a>
              <span class="line"></span>
            </li>
          </ul>
        </div>
        <!-- 联系我们 -->
        <div>
          <span class="jxd-contact">400-600-7966</span>
          <el-button @click="openDialog" class="nav-btn">预约使用</el-button>
        </div>
      </div>
    </div>
    <!-- contact ocr -->
    <div class="conact-wrapper">
      <div class="contact-box">
      <div class="contact-item" @click="openDialog">
        <img src="../assets/fxiImg/person.png" alt="" class="img-27">
        <span class="text">免费体验</span>
      </div>
      <div class="contact-item ">
        <img src="../assets/fxiImg/telphone.png" alt="" class="img-27 tel-hover">
        <span class="text tel-hover">电话咨询</span>
        <div class="tel-box">
          <img src="../assets/fxiImg/bluephone.png" alt="">
          <span>400-600-7966</span>
        </div>
      </div>
      <div class="contact-item ">
        <img src="../assets/fxiImg/weixin.png" alt="" class="img-36 weixin-hover">
        <span class="text weixin-hover">微信咨询</span>
        <div class="weixin-box">
          <div class="qcr-box">
            <img src="../assets/fxiImg/liu_wein2.png" alt="">
          </div>
          <span>获取专业解决方案</span>
        </div>
      </div>
    </div>
    <div class="scrool-top" @click="scroolTopFn" v-if="scroolTop"></div>
    </div>
    
   <!--  <iframeBox :dialogVisible.sync="frameVisible"></iframeBox> -->
    <diaForm :dialogVisible.sync="frameVisible" ref="formRef"></diaForm>
  </div>
</template>
<script>
import diaForm from '@/components/dialogForm.vue'
export default {
  name: '',
  components: {diaForm },
  created() {
    //页面刷新时 session里边也有备份 导航状态有保存
    this.scrollFn()
    var temp = sessionStorage.getItem('active')
    if (!temp) {
      sessionStorage.setItem('active', 1)
    } else {
      this.$store.commit('changeActive', temp)
    }
  },
  mounted(){
    this.$refs.formRef.$refs.diaRef.rendered = true
  },
  data() {
    return {
      frameVisible: false,
      scroll: '1',
      active: '1',
      show: '',//安全百科
      scroolTop: false,
      dialogVisible:false
    }
  },
  watch: {
    $route: {
      handler(to) {
        switch (to.name) {
          case 'app':
            sessionStorage.setItem('active', 1)
            this.$store.commit('changeActive', 1)
            this.scroll = 1
            break;
          case 'product':
            sessionStorage.setItem('active', 2)
            this.$store.commit('changeActive', 2)

            break;
          case 'policy':
            sessionStorage.setItem('active', 3)
            this.$store.commit('changeActive', 3)

            break;
          case 'column':
            if (to.params.id) {
              this.scroll = 2
            } else {
              this.scroll = 1
            }
            sessionStorage.setItem('active', 4)
            this.$store.commit('changeActive', 4)
            break;
          case 'details':
            sessionStorage.setItem('active', 4)
            this.$store.commit('changeActive', 4)
            this.scroll = 2
            break;
          default:
            break;
        }
      },
      immediate: true
    },
  },
  methods: {
    /* 页面滚动 */
    scrollFn() {

      let that = this
      //滚动前判断是否是详情页 详情页背景是白色 
      window.addEventListener('scroll', () => {

        let num = window.scrollY
        if (num > 1000) {
          this.scroolTop = true
        } else {
          this.scroolTop = false
        }
        if (that.$route.name !== 'details') {
          if (that.$route.name == 'column' && that.$route.params.id) {
            that.scroll = 2
          } else {
            if (num < 80) {
              that.scroll = 1
            } else {
              that.scroll = 2
            }
          }
        }
        else {
          that.scroll = 2
        }
      })
    },
    //打开弹框
    openDialog() {
      
      this.frameVisible = true
    },
    scroolTopFn() {
      window.scroll({
        top: 0,
        left: 0,
        /* behavior: 'smooth' */
      });
    },
    handleClickNav(e) {

      let that = this;
      switch (e.target.dataset.id) {

        case "1":
          sessionStorage.setItem('active', 1)
          that.$router.push("/");
          that.scroll = 1
          break;
        case "2":
          sessionStorage.setItem('active', 2)
          that.$router.push("/product");
          that.scroll = 1
          break;
        case "3":
          sessionStorage.setItem('active', 3)
          that.$router.push("/policy");
          that.scroll = 1
          break;
        case "4":
          sessionStorage.setItem('active', 4)
          that.$router.push({ name: "column" });
          that.$EventBus.$emit('safe')
          that.scroll = 1
          break;
        case "5":
          window.open('http://www.jxdinfo.com/overview/')
          break;
        default:
          break;
      }

    },
  }
}

</script>
<style scoped lang="less">
.conact-wrapper{
  position: fixed;
  z-index: 9999;
  right: 5px;
  top: 40vh;
}
.contact-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 81px;
  height: 241px;
  padding: 20px 5px 5px 5px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #196cf6;

  .img-27 {
    width: 27px;
    height: 27px;
  }

  .img-36 {
    width: 36px;
    height: 28px;
  }

  .contact-item:not(:last-child) {
    border-bottom: 1px solid #fff;
  }

  .contact-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2px 0 2px;
    color: #fff;

    cursor: pointer;

    img {
      margin-bottom: 5px;

    }

    .text {
      margin-bottom: 12px;
      font-size: 14px;
    }

    .tel-box {

      display: flex;
      align-items: center;
      position: absolute;
      opacity: 0;
      left: -180px;
      width: 161px;
      height: 51px;
      padding: 5px 10px;
      box-sizing: border-box;
      background-image: url(../assets/fxiImg/hoverbg.png);
      background-size: cover;
      border-radius: 3px;
      color: #317ef6;
      font-size: 16px;
      font-weight: 700;

      img {
        width: 17px;
        height: 17px;
        margin: 0 5px 0 0;
      }
    }

    .weixin-box {
      position: absolute;
      left: -180px;
      top: 20px;
      opacity: 0;
      width: 157px;
      height: 181px;
      border-radius: 3px;
      background-image: url('../assets/fxiImg/weinxin-bg.png');
      padding: 10px;
      box-sizing: border-box;
      text-align: center;

      .qcr-box {
        width: 135px;
        height: 134px;
        margin-bottom: 5px;
      }

      img {
        width: 100%;
        height: 100%;
      }

      span {
        color: #707070;
        font-size: 14px;

      }
    }
  }

  .tel-hover:hover~.tel-box {
    opacity: 1;
  }

  .weixin-hover:hover~.weixin-box {
    opacity: 1;
  }
}

.scrool-top {
  background-image: url('../assets/fxiImg/scroolTop.png');
  width: 65px;
  height: 65px;
  z-index: 9999;
  cursor: pointer;
}

.fix {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}



.pre-color {
  background: transparent;

  .line {
    display: inline-block;
    position: relative;
    top: 5px;
    width: 0;
    height: 2px;
    background-color: #fff;
  }

  li:hover .line {
    transition: all .5s ease;
    display: inline-block;
    position: relative;
    top: 5px;
    width: 100%;
    height: 2px;
    background-color: #fff;
  }

  .active {
    a {
      color: #fff;
      position: relative;
      display: inline-block;
    }

    .line {
      transition: all .5s ease;
      display: inline-block;
      position: relative;
      top: 5px;
      width: 100%;
      height: 2px;
      background-color: #fff;
    }
  }


  a {
    color: #fff;
  }

  .stock-box {
    color: #fff;
  }

  .jxd-contact {
    color: #fff;
  }

  .jxd-contact::before {
    content: '';
    display: inline-block;
    background-image: url(@/assets/index/phone_white.f94f539b.gif);
    background-size: 100% 100%;
    width: 20px;
    height: 20px;
    position: relative;
    top: 4px;
    margin-right: 4px;
  }
}

.after-color {
  background-color: #f5f9fd;
  color: #2e2e2eff;

  .line {
    display: inline-block;
    position: relative;
    top: 5px;
    width: 0;
    height: 2px;
    background-color: #3d91ff;
  }

  li:hover .line {
    transition: all .5s ease;
    display: inline-block;
    position: relative;
    top: 5px;
    width: 100%;
    height: 2px;
    background-color: #3d91ff;
  }

  .active {
    a {
      color: #3d91ff
    }

    .line {
      display: inline-block;
      position: relative;
      top: 5px;
      width: 100%;
      height: 2px;
      background-color: #3d91ff;
    }
  }

  .jxd-contact::before {
    content: '';
    display: inline-block;
    background-image: url(@/assets/index/phone_black.35a052eb.gif);
    background-size: 100% 100%;
    width: 20px;
    height: 20px;
    position: relative;
    top: 4px;
    margin-right: 4px;
  }
}

.jxd-navbar {
  margin: 0 auto;
  width: 1200px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;


  .nav-logo {
    float: left;
    width: 128px;
    height: 40px;

    img {
      width: 100%;
    }
  }

  .nav-box {
    color: #3E3A39;
    width: 500px;

    ul {

      display: flex;
      justify-content: space-evenly;
      padding-top: 29px;

      .active {
        a {
          font-weight: 700;
        }

      }

      li {
        font-size: 18px;
        height: 50px;
        text-align: center;
        overflow: hidden;

      }

      li:hover>a {
        cursor: pointer;
      }


    }
  }

  .stock-box {
    float: left;
    margin-left: 14px;
    text-align: left;
    font-size: 14px;

    .stock-name {
      display: block;
      line-height: 22px;
    }
  }

  .jxd-contact {
    height: 100%;
    font-size: 18px;
    line-height: 32px;
    font-family: "PINGFANG HEAVY"
  }



  .el-button {
    padding: 0;
  }

  .nav-btn {
    background-color: #f19f35;
    border-color: #f19f35;
    margin-left: 45px;
    width: 106px;
    height: 42px;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
  }

  .nav-btn:hover {
    background-color: #f3c715;
    border-color: #f3c715;

  }
}
</style>