<template>
  <div class="five-value"> 
    <div class="title">{{title}}</div>
    <div class="content">
      <div class="top-content">
        <div class="top-left item">
          <img class="img" src="@/assets/mobile/product-img/qyaq.png">
          <div class="text">{{text[0]}}</div>
        </div>
        <div class="top-right item">
          <img class="img" src="@/assets/mobile/product-img/cpsj.png">
          <div class="text">{{text[1]}}</div>
        </div>
      </div>
      <div class="bottom-content">
        <div class="bottom-left item">
          <img class="img" src="@/assets/mobile/product-img/jscb.png">
          <div class="text">{{text[2]}}</div>
        </div>
        <div class="bottom-center item">
          <img class="img" src="@/assets/mobile/product-img/qy.png">
          <div class="text">{{text[3]}}</div>
        </div>
        <div class="bottom-right item">
          <img class="img" src="@/assets/mobile/product-img/zfjg.png">
          <div class="text">{{text[4]}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: '',
  components: {},
  created() {

  },
  data() {
    return {
      title: '五大价值',
      text: [
        '保障企业安全',
        '人性化产品设计',
        '降低运维成本',
        '促进全员参与',
        '符合政府监管',
      ]
    }
  },
  methods: {

  }
}

</script>
<style scoped lang="less">
.five-value {
  width: 100%;
  height: 400px;
  background: url('@/assets/mobile/product-img/value@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 50px;

}

.title {
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  width: fit-content;
  margin: auto;
  margin-bottom: 20px;
}

.content {
  width: calc(100% - 40px);
  height: 300px;
  margin: 35px 20px;
  background: #F4F8FF;
  border-radius: 8px;

  .top-content {
    display: flex;
    height: 50%;

    .top-left {
      border-right: 1px #DDE5F3 solid;
    }
  }

  .bottom-content {
    display: flex;
    height: 50%;

    .bottom-left {
      border-top: 1px #DDE5F3 solid;
      border-right: 1px #DDE5F3 solid;
    }

    .bottom-center {
      border-top: 1px #DDE5F3 solid;
    }

    .bottom-right {
      border-top: 1px #DDE5F3 solid;
      border-left: 1px #DDE5F3 solid;
    }

    
  }

  .item {
    display: flex;
    margin: auto;
    flex-direction: column;
    width: 50%;
    height: 100%;

  }

  .img {
    display: flex;
    margin: auto;
    width: 50px;
    width: 50px;

  }

  .text {
    height: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #2E2E2E;
    text-align: center;
  }
  
}




</style>