import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
const store = new Vuex.Store({
  state () {
    return {
      active: 1,
      metaInfo:{},
      popStatus:false,
      mobileActive:1
    }
  },
  mutations: {
    changeActive(state,payload){
      state.active = payload
    },
    CHANGE_META_INFO(state,metaInfo){
      state.metaInfo = metaInfo
    },
    CHANGE_POP_STATUS(state,val){
      state.popStatus = val
    },
    CHANGE_MOBILE_ACTIVE(state,val){
      state.mobileActive = val
    }
  }
})
export default store