<!--    文章列表组件    -->
<template>
	<div class="article-list">
		<div class="section-trade-main" v-for="(item, index) in articleList" :key="index" @click="clickHotArticle(item)">
			<div class="section-trade-main-left">
				<div class="left-title">{{ item.title }}</div>
				<div class="left-time">
					<div class="trade-time">{{ item.releaseDate }}</div>
					<div class="trade-number">
						<i class="el-icon-view" style="font-size:14px;margin-right:4px;"></i>
						<span>{{ item.contentCount }}</span>
					</div>
				</div>
			</div>
			<div class="section-trade-main-right">
				<el-image :src="imgURL(item.typeImg)"></el-image>
			</div>
		</div>
		
		<div class="section-company-btn" v-if="isShowPagination">
			<div class="paging">
				<pagination v-model="currentPage" :page-count="total" mode="simple" @change="handleCurrentChange">
					<template #prev-text>
						<i class="icon_prve"></i>
					</template>
					<template #next-text>
						<i class="icon_next"></i>
					</template>
				</pagination>
			</div>
		</div>
		<div class="more-btn" v-else @click="moreBtn">查看更多</div>
	</div>
</template>
<script>
import pagination from 'vant/lib/pagination';
export default {
	name: 'articleListComponent',
	components: {
		pagination,
	},
	props: {
		// 页面容量
		pageSize: {
			type: Number,
			default: 10,
		},
		// 分页是否显示
		isShowPagination: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			// 百科id
			safeId: "277",
			// 全部文章
			articleList: [],
			// 当前页
			currentPage: 1,
			total: 0,
		}
	},
	created() {
		this.getArticleList();
	},
	methods: {
		/** 图片处理 */
		imgURL(data) {
			return this.$imgURL + data;
		},

		/** 获取全部文章 */
		getArticleList() {
			let _this = this;
			let data = {
				channelId: _this.safeId,
				pageNo: _this.currentPage,
				pageSize: _this.pageSize
			};
			_this.axios.postForm("/contentInfo_channelId.jspx", data)
				.then(res => {
					if (res.status == 200) {
						_this.articleList = res.data.content;
						_this.articleList.forEach(item => {
							let date = item.releaseDate.slice(0, 10)
							let time = item.releaseDate.slice(10, 18)
							item.releaseDate = item.releaseDate.replace(item.releaseDate, `${date}\xa0${time}`)
						})
						_this.total = Math.ceil(res.data.total / 10);
					}
				})
				.catch(err => console.log(err));
		},
		moreBtn(){
			this.$router.push({name:'column'})
			this.$store.commit('CHANGE_MOBILE_ACTIVE',3)
		},
		/** 改变当前所在页 */
		handleCurrentChange(val) {
			this.currentPage = val;
			// 更改列表当前页数，刷新列表
			this.getArticleList();
		},

		/** 跳转文章详情 */
		clickHotArticle(val) {
			let obj = {}
			obj.id = val.id
			obj.sort = '';
			this.$router.push({ name: "details", params: obj });
		},
	}
}
</script>
<style scoped lang="less">
.article-list {

	.section-trade-main {
		width: 100%;
		border-bottom: solid 0.5px #d1d1d1;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding-bottom: 9px;
		margin-top: 10px;

		.section-trade-main-left {
			width: calc(100% - 142px);
			height: 88px;

			.left-title {
				width: 100%;
				word-wrap: break-word;
				font-size: 14px;
				line-height: 19px;
				color: #2e2e2e;
				margin-top: 7px;
				margin-bottom: 16.5px;
				height: 38px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				/* 几行开始截断 */
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
				word-break: break-all;
			}

			.left-time {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				font-size: 12px;
				color: #A7A7A7;
				line-height: 16px;

				.trade-number {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					margin-right: 15px;
				}
			}
		}

		.section-trade-main-right {
			width: 132px;
			height: 88px;
			margin-left: 9.5px;

			.el-image {
				width: 125px;
				height: 72px;
				margin-top: 8px;
				border-radius: 5px;
			}
		}
	}

	.more-btn {
		width: 100px;
		height: 37px;
		line-height: 37px;
		text-align: center;
		background: #0D80FF;
		border-radius: 5px;
		color: #fff;
		margin: 36px auto 0;
		
	}

	.section-company-btn {
		width: 100%;
		height: 36px;
		margin: 16px 0;
		text-align: center;

		.paging {
			width: 100%;

			::v-deep .van-pagination {
				margin-bottom: 0;
				padding-left: 0;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;

				.icon_prve {
					display: block;
					width: 28px;
					height: 28px;
					background-image: url('@/assets/mobile/icon_prev.png');
					background-size: 100% 100%;
				}

				.icon_next {
					display: block;
					width: 28px;
					height: 28px;
					background-image: url('@/assets/mobile/icon_next.png');
					background-size: 100% 100%;
				}

				.van-pagination__prev {
					margin-right: 28px;
				}

				.van-pagination__prev::after {
					border-width: 0;
				}

				.van-pagination__next {
					margin-left: 28px;
				}

				.van-pagination__next::after {
					border-width: 0;
				}

				.van-pagination__page-desc {
					font-size: 13px;
					color: #2e2e2e;
					line-height: 28px;
					height: 26.5px;
					width: 45px;
					max-width: 50px;
				}
			}
		}
	}
}</style>